import React from "react";

const ViewAll = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50"
      height="50"
    >
      <g id="categories_view_all_arrow_1" transform="translate(-298 -716)">
        <rect
          id="Rectangle_1574"
          data-name="Rectangle 1574"
          width="50"
          height="50"
          transform="translate(298 716)"
          fill="#fff"
        ></rect>
        <g id="right-arrow" transform="translate(397.276 816.384)">
          <circle
            id="Ellipse_20"
            data-name="Ellipse 20"
            cx="13"
            cy="13"
            r="13"
            transform="translate(-87.276 -88.384)"
            fill="#2497f3"
          ></circle>
          <path
            id="Path_8135"
            data-name="Path 8135"
            d="M142.285,164.44a.724.724,0,0,1,0,1.011l-3.51,3.606a.733.733,0,0,1-1.033.016.724.724,0,0,1-.017-1.028l2.31-2.374h-8.371a.727.727,0,1,1,0-1.454h8.371l-2.31-2.374a.724.724,0,0,1,.016-1.028.733.733,0,0,1,1.033.016Z"
            transform="translate(-210.986 -240.329)"
            fill="#fff"
            fill-rule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default ViewAll;
