import React, { useEffect, useState } from "react";
import MenuIcon from "../SVGs/MenuIcon";
import tw from "tailwind-styled-components";
import Logo from "../SVGs/Logo";
import AWCCLogo from "../SVGs/AWCCLogo";
import CrossIcon from "../SVGs/CrossIcon";
import { Link } from "react-router-dom";
import UnsubscribeModel from "../UnsubscribeModel";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../Config";
import axios from "axios";
import { Remove_User } from "../../Redux/actions";
import { useTranslation } from "react-i18next";

const HomeNavbar = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);

  const [activeLang, setActiveLang] = useState(i18n.language || "english");

  useEffect(() => {
    setActiveLang(i18n.language);
  }, [i18n]);

  const openSidebar = () => setIsMenuOpen(true);
  const closeSidebar = () => setIsMenuOpen(false);
  const openModelFunc = () => {
    setOpenModel(true);
    closeSidebar();
  };
  const closeModelFunc = () => setOpenModel(false);
  const user = useSelector((state) => state.UserReducer.user);

  const unsubscribeUserApi = async () => {
    const body = new FormData();
    body.append("mobile", user?.mobile);

    const resp = await axios.post(Config.UnsuscribeUser, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
    if (resp.data.status === "OK") {
      dispatch(Remove_User());
      window.location.replace(`/`);
    } else {
      //setAlert({ msg: resp.data.msg, show: true, color: "red" });
      //clearAlert();
    }
  };

  const languageChangeHandler = (e) => {
    const { value } = e.target;
    setActiveLang(value);
    i18n.changeLanguage(value);
    closeSidebar();
  };

  return (
    <Wrapper>
      {openModel && (
        <UnsubscribeModel
          setOpenModel={setOpenModel}
          closeModelFunc={closeModelFunc}
          unsubHandler={unsubscribeUserApi}
        />
      )}

      <SidebarComp
        isMenuOpen={isMenuOpen}
        closeSidebar={closeSidebar}
        openModelFunc={openModelFunc}
        activeLang={activeLang}
        languageChangeHandler={languageChangeHandler}
        t={t}
      />
      <LogoMenuWrapper>
        <div className="cursor-pointer" onClick={openSidebar}>
          <MenuIcon />
        </div>
        <Logo />
      </LogoMenuWrapper>
      <AWCCLogo />
    </Wrapper>
  );
};

const SidebarComp = ({
  isMenuOpen,
  closeSidebar,
  openModelFunc,
  activeLang,
  languageChangeHandler,
  t,
}) => (
  <SidebarWrapper $isMenuOpen={isMenuOpen}>
    <Sidebar>
      <CloseBtn onClick={closeSidebar}>
        <CrossIcon />
      </CloseBtn>

      <List>
        <Link to="/">
          <MenuItem>{t("Home")}</MenuItem>
        </Link>
        <Link to="/doctor-list">
          <MenuItem>{t("ConsultDoctor")}</MenuItem>
        </Link>

        <Link to="/medical-stores">
          <MenuItem>{t("MedicalCall")}</MenuItem>
        </Link>

        <Link to="/community">
          <MenuItem>{t("CommunityTitle")}</MenuItem>
        </Link>

        <Link to="/doctor-profile">
          <MenuItem>{t("DoctorAdvice")}</MenuItem>
        </Link>
        {/* <MenuItem onClick={closeSidebar}>Terms & Conditions </MenuItem>
        <MenuItem onClick={closeSidebar}>FAQs </MenuItem> */}
        <MenuItem onClick={openModelFunc}>{t("UnsubscribeUser")} </MenuItem>

        <MenuItem className="flex items-center space-x-2">
          <span>{t("Langauge")} :</span>
          <select
            className="text-gray-700 text-sm p-1 w-28 rounded"
            onChange={languageChangeHandler}
            value={activeLang}
          >
            <option value="english">English</option>
            <option value="pashto">Pashto</option>
            <option value="dari">Dari</option>
          </select>
        </MenuItem>
      </List>
    </Sidebar>
    <BlackBg onClick={closeSidebar}></BlackBg>
  </SidebarWrapper>
);

const Wrapper = tw.nav`h-20 bg-white flex items-center justify-between px-4 z-50 `;
const LogoMenuWrapper = tw.div`flex items-center space-x-5`;

const SidebarWrapper = tw.div`
${(p) => (p.$isMenuOpen ? " opacity-100" : " pointer-events-none opacity-0")}
Container -translate-x-4 h-screen flex fixed top-0 w-full  transition duration-200 z-50`;
const Sidebar = tw.div`h-full bg-custom-blue text-white w-8/12 z-40 `;
const BlackBg = tw.div`bg-alpha-black w-4/12 h-full`;
const CloseBtn = tw.div`w-full mt-2  py-2.5 flex justify-end items-center p-3 pointer-cursor`;

const List = tw.ul``;
const MenuItem = tw.li`p-3 cursor-pointer text-gray-100 hover:bg-orange-400 border-b border-gray-300 text-lg`;

export default HomeNavbar;
