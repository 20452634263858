import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import CallIcon from "../Components/SVGs/CallIcon";
import LocationIcon from "../Components/SVGs/LocationIcon";
import VideoFilledIcon from "../Components/SVGs/VideoFilledIcon";
import Doctor from "../Components/SVGs/Doctor";
import Config from "../Config";
import axios from "axios";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

const HospitalList = () => {
  //const list = [1, 2, 3, 4, 5];
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [list, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.UserReducer.user);

  const WhatsAppHandler = (mobile) => {
    window.open(`https://wa.me/${mobile}`, "_blank");
  };

  const fetchHospitalsList = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("lang", i18n.language);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.GetHospitalsList, body, axiosConfig);

      setLoading(false);
      if (res.data.status === "OK") {
        setData(res.data.list);
      } else {
        dispatch(Remove_User());
        window.location.replace(`/`);
      }
    } else {
      // dispatch(Remove_User());
    }
  };

  useEffect(() => {
    fetchHospitalsList();
  }, []);

  const goToDepartments = (id) => {
    navigate(`/hospital/${id}`);
  };

  return (
    <Container>
      <DetailNavbar title={t("HospitalsList")} route={-1} />
      <Wrapper>
        {/* loader */}
        {loading && (
          <div
            className={`w-full ${loading && "h-96"} grid place-items-center`}
          >
            <img
              src={Images.GreenLoader}
              alt="loading..."
              className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
            />
          </div>
        )}

        {!loading &&
          list.map((item) => (
            <SingleItem
              key={item.id}
              Name={item.name}
              mobile={item.msisdn}
              navigate={navigate}
              id={item.id}
              goToDepartments={goToDepartments}
            />
          ))}
      </Wrapper>
    </Container>
  );
};

const SingleItem = ({
  img,
  Name,
  designation,
  location,
  handler,
  mobile,
  navigate,
  id,
  goToDepartments,
}) => (
  <SingleItemWrapper onClick={() => goToDepartments(id)}>
    <Figure>
      <img
        src={Images.Hospital}
        alt="images"
        className="w-full h-full object-cover"
      />
    </Figure>
    <TextWrapper>
      <Title>{Name}</Title>
      {/* <Location>
        <LocationIcon />
        <p>{location}</p>
      </Location> */}
    </TextWrapper>
    <Icons>
      <Btn>
        <BsArrowRightShort className="w-8 text-white" />
      </Btn>
    </Icons>
  </SingleItemWrapper>
);

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;

const Wrapper = tw.div`flex flex-col space-y-5 mt-5 px-4`;

const SingleItemWrapper = tw.div`
w-full bg-white shadow-md p-3 rounded-lg flex space-x-2 items-center cursor-pointer `;
const Btn = tw.button` bg-custom-blue border flex items-center  justify-center w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const TextWrapper = tw.div`w-full`;
const Title = tw.h3`font-medium text-gray-900 text-lg `;
const Designation = tw.div`text-sm text-gray-500`;
const Location = tw.div`flex space-x-1 items-center text-sm text-gray-500 `;
const Icons = tw.div`flex flex-col justify-start px-2 py-3  space-y-2`;
const Figure = tw.figure`w-36 h-20 rounded-lg overflow-hidden`;

export default HospitalList;
