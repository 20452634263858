import React from "react";

const WellnessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50"
      height="50"
    >
      <g id="wellness_1" transform="translate(-278 -228)">
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="50"
          height="50"
          transform="translate(278 228)"
          fill="#fff"
        ></rect>
        <g
          id="healthcare_1_"
          data-name="healthcare (1)"
          transform="translate(283.499 235)"
        >
          <g
            id="Group_2898"
            data-name="Group 2898"
            transform="translate(7.757 25.222)"
          >
            <path
              id="Path_8061"
              data-name="Path 8061"
              d="M94.8,355.665c-2.267-.674-4.428-.861-6.541.358a3.772,3.772,0,0,1-2.448.867H85.3v5.874a12.246,12.246,0,0,1,4.337.594c1.714.5,2.191.643,3.9,1.147a13.525,13.525,0,0,0,3.2.719h.008a4.814,4.814,0,0,0,2.73-1.007l8.049-5.739a1.808,1.808,0,1,0-1.9-3.077L98.784,358.8a1.885,1.885,0,0,0-1.272-2.325Z"
              transform="translate(-85.299 -355.133)"
              fill="#ffdcd5"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_2899"
            data-name="Group 2899"
            transform="translate(2.188 25.83)"
          >
            <path
              id="Path_8062"
              data-name="Path 8062"
              d="M12.649,363.985a1.152,1.152,0,0,0-1.148,1.149v6.446a1.152,1.152,0,0,0,1.148,1.149h2.774a1.153,1.153,0,0,0,1.148-1.149v-6.446a1.153,1.153,0,0,0-1.148-1.149Z"
              transform="translate(-11.501 -363.985)"
              fill="#9de3d1"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_2900"
            data-name="Group 2900"
            transform="translate(9.84 6.733)"
          >
            <path
              id="Path_8063"
              data-name="Path 8063"
              d="M114.876,96.273,113.1,98.822l-1.9-2.549H107.9a7.183,7.183,0,0,0,1.909,3.823l9.757,9.426,9.757-9.426a7.182,7.182,0,0,0,1.909-3.823h-3.309l-1.9,2.549-1.774-2.549-1.681,3.356-3.006-5.552-3.006,5.552Z"
              transform="translate(-107.897 -94.076)"
              fill="#ff9eb1"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_2901"
            data-name="Group 2901"
            transform="translate(9.738 0.687)"
          >
            <path
              id="Path_8064"
              data-name="Path 8064"
              d="M117.094,11.872c-2.451-2.294-6.325-2.619-8.67-.191a7.337,7.337,0,0,0-1.909,6.292h3.308l1.9,2.549,1.774-2.549,1.681,3.356,3.006-5.552,3.006,5.552,1.681-3.356,1.774,2.549,1.9-2.549h3.309a7.338,7.338,0,0,0-1.909-6.292c-2.344-2.429-6.218-2.1-8.67.191C118.148,12.92,118.214,12.92,117.094,11.872Z"
              transform="translate(-106.414 -10)"
              fill="#ff9eb1"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_2902"
            data-name="Group 2902"
            transform="translate(1.501 0)"
          >
            <path
              id="Path_8065"
              data-name="Path 8065"
              d="M27.281,345.467l-5.846,2.9a2.577,2.577,0,0,0-1.769-1.874l-2.708-.8a8.65,8.65,0,0,0-7.081.42c-.159.092-.3.184-.427.265a2.483,2.483,0,0,1-1.639.511,1.837,1.837,0,0,0-1.7-1.148H3.336A1.838,1.838,0,0,0,1.5,347.572v6.446a1.838,1.838,0,0,0,1.835,1.836H6.11a1.837,1.837,0,0,0,1.831-1.718,10.965,10.965,0,0,1,3.46.563c.783.231,4.525,1.321,4.806,1.4a9.023,9.023,0,0,0,2.5.491,5.478,5.478,0,0,0,3.114-1.123l.017-.012,8.049-5.739a2.493,2.493,0,0,0-2.6-4.252ZM6.571,354.018a.468.468,0,0,1-.461.462H3.336a.468.468,0,0,1-.461-.462v-6.446a.468.468,0,0,1,.461-.462H6.11a.468.468,0,0,1,.461.462v6.446ZM29.086,348.6l-8.04,5.733c-1.822,1.214-2.594.99-4.455.449-.285-.083-4.018-1.171-4.8-1.4a12.165,12.165,0,0,0-3.844-.618v-4.506a3.787,3.787,0,0,0,2.249-.728c.119-.076.241-.155.37-.23,1.667-.961,3.463-1.048,6-.293l2.708.8a1.2,1.2,0,0,1,.81,1.472v0a1.191,1.191,0,0,1-1.471.8l-3.872-1.126a.687.687,0,1,0-.384,1.319l3.872,1.126a2.569,2.569,0,0,0,3.064-1.438l6.6-3.273a1.207,1.207,0,0,1,1.6.333A1.177,1.177,0,0,1,29.086,348.6Z"
              transform="translate(-1.501 -320.592)"
            ></path>
            <path
              id="Path_8066"
              data-name="Path 8066"
              d="M77.2,7.973H74.234a7.838,7.838,0,0,0-2.258-6.082A6.55,6.55,0,0,0,67,.006a7.891,7.891,0,0,0-4.971,2.051l-.1.088c-.2.185-.412.374-.541.47-.129-.1-.338-.285-.541-.47l-.1-.088A7.892,7.892,0,0,0,55.786.006a6.551,6.551,0,0,0-4.973,1.885,7.837,7.837,0,0,0-2.258,6.082H45.594a.687.687,0,1,0,0,1.374h3.179a7.785,7.785,0,0,0,2.04,3.613l.018.018.819.765a.725.725,0,0,0,1-.018.672.672,0,0,0-.018-.971L51.825,12a6.44,6.44,0,0,1-1.586-2.65h2.172l1.745,2.272a.723.723,0,0,0,1.151-.018l1.15-1.6,1.2,2.322a.712.712,0,0,0,.623.379h.011a.713.713,0,0,0,.624-.36l2.479-4.436,2.479,4.436a.72.72,0,0,0,1.258-.019L66.332,10l1.15,1.6a.723.723,0,0,0,1.151.017l1.743-2.272H72.55A6.441,6.441,0,0,1,70.964,12l-9.569,8.956-4.182-3.915a.725.725,0,0,0-1,.017.672.672,0,0,0,.017.971L60.9,22.4a.726.726,0,0,0,.985,0l10.071-9.426.018-.017a7.786,7.786,0,0,0,2.04-3.613H77.2a.687.687,0,1,0,0-1.374Zm-4.38,0h-2.8a.717.717,0,0,0-.569.277l-1.368,1.783L66.814,8.268a.721.721,0,0,0-1.216.085l-1.125,2.176L62.018,6.136a.72.72,0,0,0-1.247,0l-2.455,4.393L57.191,8.352a.721.721,0,0,0-1.216-.085l-1.269,1.766L53.336,8.25a.717.717,0,0,0-.569-.277H49.974a6.487,6.487,0,0,1,1.86-5.128c2.02-2.027,5.588-1.93,7.954.216l.1.087c.607.551.974.884,1.511.884s.9-.333,1.511-.884L63,3.06C65.368.915,68.936.818,70.955,2.844a6.488,6.488,0,0,1,1.86,5.129Z"
              transform="translate(-41.904 0)"
            ></path>
            <path
              id="Path_8067"
              data-name="Path 8067"
              d="M169.866,215.314a.684.684,0,0,0,.92-.308.688.688,0,1,0-.924.306Z"
              transform="translate(-157.587 -198.948)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WellnessIcon;
