import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import CallIcon from "../Components/SVGs/CallIcon";
import LocationIcon from "../Components/SVGs/LocationIcon";
import VideoFilledIcon from "../Components/SVGs/VideoFilledIcon";
import Doctor from "../Components/SVGs/Doctor";
import Config from "../Config";
import axios from "axios";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import InfoModel from "../Components/InfoModel";

const Appointment = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openInfoModel, setOpenInfoModel] = useState(false);
  const [infoModelMsg, setInfoModelMsg] = useState("");

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [doctorId, setDoctorId] = useState(null);

  const user = useSelector((state) => state.UserReducer.user);

  const fetchSlots = async () => {
    try {
      if (user?.mobile) {
        setLoading(true);

        const body = new FormData();
        body.append("mobile", user?.mobile);
        body.append("lang", i18n.language);
        body.append(
          "appointmentDate",
          moment(selectedDate).format("YYYY-MM-DD")
        );
        const axiosConfig = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        };
        const res = await axios.post(Config.GetSlots, body, axiosConfig);

        setLoading(false);
        if (res.data.status === "OK") {
          setData(res.data.data);
          setDoctorId(res.data.doctorId);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchSlots();
  }, [selectedDate]);

  const bookingHandler = async (item) => {
    try {
      if (user?.mobile) {
        setBookingLoading(true);

        const body = new FormData();
        body.append("mobile", user?.mobile);
        body.append("lang", i18n.language);
        body.append("doctorId", doctorId);
        body.append("startTime", item.startTime);
        body.append("endTime", item.endTime);
        body.append(
          "appointmentDate",
          moment(selectedDate).format("YYYY-MM-DD")
        );
        const axiosConfig = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        };
        const res = await axios.post(Config.BookSlot, body, axiosConfig);

        setBookingLoading(false);
        if (res.data.status === "OK") {
          setInfoModelMsg(res.data.msg);
          setOpenInfoModel(true);
        } else {
          setInfoModelMsg(res.data.msg);
          setOpenInfoModel(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Container>
      {openInfoModel && (
        <InfoModel
          setOpenModel={setOpenInfoModel}
          closeModelFunc={() => navigate("/", { replace: true })}
          msg={infoModelMsg}
        />
      )}

      <DetailNavbar title={t("AppointmentTitle")} route={-1} />
      <Wrapper>
        {/* loader */}
        {loading && (
          <div
            className={`w-full ${loading && "h-96"} grid place-items-center`}
          >
            <img
              src={Images.GreenLoader}
              alt="loading..."
              className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
            />
          </div>
        )}

        {!loading && (
          <>
            <DateWrapper>
              <p className="font-medium">{t("SelectDate")}</p>
              <DatePicker
                onChange={setSelectedDate}
                value={selectedDate}
                className="border border-gray-300 mx-10 rounded-md p-2"
                clearIcon={null}
                calendarClassName="border-none "
                format="dd/MM/y"
                minDate={new Date()}
                maxDate={new Date(moment().add("10", "d"))}
              />
            </DateWrapper>
          </>
        )}

        {!loading && data.length != 0 && (
          <TimeSlotWrapper>
            <div>
              <p className="text-center mt-8 font-medium">
                {t("SelectAvailableSlot")}
              </p>
              <div className="h-0.5 w-16 bg-gray-600 mx-auto mt-1" />
            </div>
            {!loading &&
              data.map((item, index) => (
                <SingleItem
                  key={index}
                  t={t}
                  item={item}
                  bookingHandler={bookingHandler}
                />
              ))}
          </TimeSlotWrapper>
        )}

        {!loading && data.length == 0 && (
          <Center>
            <p>
              No Slots are available for the{" "}
              {moment(selectedDate).format("DD/MM/YYYY")}.
            </p>
            <p>Please try again later</p>
          </Center>
        )}
      </Wrapper>
    </Container>
  );
};

const SingleItem = ({ t, item, bookingHandler }) => {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div
      className="relative w-auto border mx-auto max-w-xs"
      style={{ minWidth: 320 }}
    >
      {item?.isBooked && <Booked style={{ fontSize: 10 }}>BOOKED</Booked>}
      <SingleItemWrapper
        $booked={item?.isBooked}
        className={isSelected && "justify-between"}
        onClick={() => !item?.isBooked && setIsSelected(true)}
        onMouseLeave={() => setIsSelected(false)}
      >
        <p className="text-center">
          {moment(item?.startTime, "hh:mm").format("hh:mm a")} -{" "}
          {moment(item?.endTime, "hh:mm").format("hh:mm a")}
        </p>
        {isSelected && (
          <Btn onClick={() => bookingHandler(item)}> {t("Confirm")}</Btn>
        )}
      </SingleItemWrapper>
    </div>
  );
};

const DateWrapper = tw.div`w-full flex flex-col bg-white  p-2 rounded-md mx-auto shadow-md border border-gray-200 text-center max-w-xs space-y-2 pb-3 `;

const TimeSlotWrapper = tw.div`flex flex-col space-y-5`;

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;

const Wrapper = tw.div`flex flex-col space-y-5 mt-5 px-4 pb-10 h-full`;

const SingleItemWrapper = tw.div`
${(p) => (p.$booked ? "opacity-50 cursor-not-allowed" : "")}
w-full  bg-white shadow-md px-4 rounded-lg flex space-x-2 h-16 cursor-pointer text-custom-blue border border-custom-blue font-medium text-center   flex justify-center items-center space-x-5
 `;

const Btn = tw.button` p-2.5 px-4 rounded-md text-sm bg-custom-blue text-white`;

const Center = tw.div`flex flex-col justify-center items-center  pt-28`;

const Booked = tw.div`absolute top-0 first-line:left-0 right-0 text-center z-10 tracking-widest font-normal  text-white bg-custom-blue py-0.5 px-3 mx-auto rounded `;

export default Appointment;
