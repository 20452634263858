import React, { useState, useRef, useEffect } from "react";
import tw from "tailwind-styled-components";
import Logo from "../Components/SVGs/Logo";
import Config from "../Config";
import axios from "axios";
import Alert from "../Components/Alert";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Save_User_Data } from "../Redux/actions";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t, i18n } = useTranslation();
  const [billingType, setBillingType] = useState("1");

  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const FirstOTPRef = useRef(null);
  const SecondOTPRef = useRef(null);
  const ThirdOTPRef = useRef(null);
  const FourthOTPRef = useRef(null);
  const verifyOtpButton = useRef(null);

  const [activeLang, setActiveLang] = useState(i18n.language || "");

  const dispatch = useDispatch();

  const [firstName, setName] = useState("");
  
  const [mobile, setMobile] = useState("");
  const [plan, setPlan] = useState("");
  const [otp, setOtp] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });
  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const OTPChangeHandler = (e) => {
    const { name, value } = e.target;
    if (value.length <= 1) {
      setOtp((prev) => ({ ...prev, [name]: value }));
      if (value.length === 1) {
        if (name === "first") SecondOTPRef.current.focus();
        else if (name === "second") ThirdOTPRef.current.focus();
        else if (name === "third") FourthOTPRef.current.focus();
        // else if (name === "fourth") FifthOTPRef.current.focus();
      }
    }
  };

  const onSuccess = (data, values) => {
    if (data.data.status == "OK") {
      setIsOtpVisible(true);
    } else {
      setAlert({
        msg: data.data.msg || "An Error Occured",
        show: true,
        color: "red",
      });
    }
    clearAlert();
  };

  const onError = (data) => {
    console.log("error", data);
    setAlert({ msg: data.msg, show: true, color: "red" });
    clearAlert();
  };

  const onVerifyOtpSuccess = (data, values) => {
    // console.log(data.data);
    // window.location.replace(`/`);
    // clearAlert();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (isOtpVisible) {
      //verifyOtp(e);
      verifyOtpApi();
    } else {
      // setIsOtpVisible(true);
      registerUser(e);
    }
  };

  const registerUserApi = async () => {
    const body = new FormData();
    body.append("mobile", mobile);
    body.append("plan", plan);
    body.append("name", firstName);
    body.append("language", activeLang);
    //body.append("billingType", billingType);
    body.append("billingType", 2);
    return await axios.post(Config.RegisterApi, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    });
  };

  const { mutate: registerUser, isLoading } = useMutation(registerUserApi, {
    onSuccess,
    onError,
  });

  const verifyOtpApi = async () => {
    const body = new FormData();
    body.append("mobile", mobile);
    body.append("otp", otp.first + otp.second + otp.third + otp.fourth);
    body.append("language", activeLang);
    const resp = await axios.post(Config.VerifyOtpApi, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
    //console.log(resp);
    if (resp.data.status === "OK") {
      // window.location.replace(`/`);
      // clearAlert();
      //console.log("getting data", resp.data);
      dispatch(Save_User_Data({ ...resp.data, mobile, firstName }));
    } else {
      setAlert({ msg: resp.data.msg, show: true, color: "red" });
      clearAlert();
    }
  };

  const { mutate: verifyOtp, isOtpVerifyLoading } = useMutation(verifyOtpApi, {
    onVerifyOtpSuccess,
    onError,
  });

  useEffect(() => {
    setActiveLang(i18n.language);
  }, [i18n]);

  const languageChangeHandler = (e) => {
    const { value } = e.target;
    setActiveLang(value);
    i18n.changeLanguage(value);
  };

  return (
    <Container>
      <Wrapper>
        <div className="mb-28 mt-8">
          <Logo />
        </div>
        {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}
        <Title>
          {isOtpVisible ? (
            t("OtpVerification")
          ) : (
            <span>
              {t("LoginText")}
              {/* <span className="text-custom-blue ">iCare Portal</span> */}
            </span>
          )}
        </Title>
        {isOtpVisible && <SubTitle>{t("OtpSent")}</SubTitle>}

        <form onSubmit={submitHandler}>
          {!isOtpVisible && (
            <>
              <InputGroup>
                <Label>+93</Label>
                <Input
                  name="mobile"
                  type="number"
                  placeholder="999 999 999"
                  autoComplete="off"
                  maxLength="9"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                  required
                />
              </InputGroup>
              {errorMessage && <ErrorMessage msg={errorMessage} />}

              <InputGroup>
                <FirstNameInput
                  name="firstName"
                  type="text"
                  placeholder={t("YourName")}
                  autoComplete="off"
                  maxLength="20"
                  onChange={(e) => setName(e.target.value)}
                  value={firstName}
                  required
                />
              </InputGroup>
              {errorMessage && <ErrorMessage msg={errorMessage} />}

              <InputGroup>
                <Select
                  onChange={languageChangeHandler}
                  value={activeLang}
                  required
                >
                  <option value="" disabled>
                    {t("Select Language")}
                  </option>
                  <option value="english">English</option>
                  <option value="pashto">Pashto</option>
                  <option value="dari">Dari</option>
                </Select>
              </InputGroup>

              <InputGroup>
                <Select
                  value={plan}
                  onChange={(e) => setPlan(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    {t("SelectPlan")}
                  </option>
                  <option value="1">{t("daily")}</option>
                  <option value="30">{t("Monthly")}</option>
                </Select>
              </InputGroup>
              {/*
              <InputGroup>
                  <Select
                    value={billingType}
                    onChange={(e) => setBillingType(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      {t("SelectPlan")}
                    </option> 
                    <option value="1">{t("auto")}</option>
                    <option value="2">{t("onetime")}</option>
                  </Select>
                </InputGroup>
                */}
            </>
          )}

          {isOtpVisible && (
            <OTPInputGroup>
              <OTPInput
                type="number"
                autoComplete="off"
                maxLength="1"
                value={otp.first}
                name="first"
                ref={FirstOTPRef}
                onChange={(e) => OTPChangeHandler(e)}
              />
              <OTPInput
                type="number"
                autoComplete="off"
                maxLength="1"
                value={otp.second}
                name="second"
                ref={SecondOTPRef}
                onChange={(e) => OTPChangeHandler(e)}
              />
              <OTPInput
                type="number"
                autoComplete="off"
                maxLength="1"
                value={otp.third}
                name="third"
                ref={ThirdOTPRef}
                onChange={(e) => OTPChangeHandler(e)}
              />
              <OTPInput
                type="number"
                autoComplete="off"
                maxLength="1"
                value={otp.fourth}
                name="fourth"
                ref={FourthOTPRef}
                onChange={(e) => OTPChangeHandler(e)}
              />
            </OTPInputGroup>
          )}

          <SubmitBtn type="submit" disabled={mobile ? false : true}>
            {isOtpVisible ? t("Login") : t("GetOtp")}
          </SubmitBtn>
        </form>
      </Wrapper>
    </Container>
  );
};

const ErrorMessage = (msg) => <ErrorText>{msg}</ErrorText>;

const Container = tw.section`Container shadow-lg border relative bg-white shadow-md min-h-screen overflow-y-auto  overflow-x-hidden login-bg  `;
const Wrapper = tw.div`w-full pt-8 px-10 pb-10 h-full`;

const Title = tw.h3` text-gray-800 text-3xl font-medium mt-5 leading-10  w-64`;
const SubTitle = tw.h3`w-3/6 text-gray-800  mt-3`;

const InputGroup = tw.div`relative mt-8   `;
const OTPInputGroup = tw.div`mt-8 flex items-center space-x-5 justify-between   `;

const Label = tw.label`absolute top-0 left-0 bottom-0 w-12 flex justify-center items-center text-lg font-medium text-gray-600 `;

const Input = tw.input`w-full focus:outline-0 border-b border-custom-blue pl-12 text-lg text-gray-700 font-medium px-2 py-3 rounded-md `;

const FirstNameInput = tw.input`w-full focus:outline-0 border-b border-custom-blue pl-2 text-lg text-gray-700 font-medium px-2 py-3 rounded-md `;


const Select = tw.select`w-full focus:outline-0 border-b border-custom-blue  text-lg text-gray-700 font-medium px-2 py-3 rounded-md`;

const OTPInput = tw.input`w-full focus:outline-0 border border-custom-blue h-16 w-16 text-gray-700 font-medium  rounded-md text-center text-xl `;

const SubmitBtn = tw.button`w-full bg-custom-blue text-white text-center py-3 rounded-md mt-10 tracking-wider uppercase font-medium hover:bg-blue-800 transition duration-300 hover:shadow-lg shadow-md`;

const ErrorText = tw.p` text-red-700 text-sm my-1`;

export default Login;
