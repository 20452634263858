import React from "react";

const BackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34 34"
      width="35"
      height="35"
    >
      <g id="menu_back_arrow" transform="translate(-10 -122)">
        <rect
          id="Rectangle_15"
          data-name="Rectangle 15"
          width="34"
          height="34"
          rx="10"
          transform="translate(10 122)"
          fill="#1467b3"
        ></rect>
        <path
          id="icon_back"
          d="M10.89,13.4l5.462-5.947a1.209,1.209,0,0,0,0-1.7,1.183,1.183,0,0,0-1.685,0l-6.66,6.8a1.212,1.212,0,0,0,0,1.7l6.66,6.8a1.183,1.183,0,0,0,1.685,0,1.209,1.209,0,0,0,0-1.7Z"
          transform="translate(14.342 125.601)"
          fill="#fff"
        ></path>
      </g>
    </svg>
  );
};

export default BackIcon;
