import React from "react";
import BackIcon from "./SVGs/BackIcon";
import tw from "tailwind-styled-components";
import AWCCLogo from "./SVGs/AWCCLogo";
import { useNavigate } from "react-router-dom";

const DetailNavbar = ({ title, route }) => {
 
  const navigate = useNavigate();

  const backFunction = () => {
    if (route) return navigate(route);
    return navigate("/");
  };

  return (
    <Wrapper>
      <LogoMenuWrapper>
        <div className="cursor-pointer" onClick={backFunction}>
          <BackIcon />
        </div>
        <Title>{title}</Title>
      </LogoMenuWrapper>
      <AWCCLogo />
    </Wrapper>
  );
};

const Wrapper = tw.nav`h-20 bg-white flex items-center justify-between px-4 z-50`;
const LogoMenuWrapper = tw.div`flex items-center space-x-5`;
const Title = tw.h2`text-xl font-medium text-custom-blue capitalize`;

export default DetailNavbar;
