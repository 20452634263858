import React from "react";

const Doctor = ({ width = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "70"}
      height="70"
      viewBox="0 0 60 60"
    >
      <g id="doctor_avatar1" transform="translate(-20 -190)">
        <rect
          id="Image"
          width="60"
          height="60"
          rx="10"
          transform="translate(20 190)"
          fill="#f1f5f8"
        />
        <g
          id="doctor_2_"
          data-name="doctor (2)"
          transform="translate(25 194.03)"
        >
          <path
            id="Path_8137"
            data-name="Path 8137"
            d="M50,354.163v7.551H0v-7.551a4.843,4.843,0,0,1,3.072-4.518l10.543-4.162,8.229,9.079,2.582-5.281v-.01L25,348.087l.584,1.183,2.583,5.292,8.218-9.079,10.553,4.162A4.861,4.861,0,0,1,50,354.163"
            transform="translate(0 -310.744)"
            fill="#f2f2f2"
          />
          <path
            id="Path_8138"
            data-name="Path 8138"
            d="M195.146,289.691h.01v-4.3a23.156,23.156,0,0,1-1.928,2.523l-1.025,1.13a5.009,5.009,0,0,1-3.671,1.621h-1.569a5,5,0,0,1-3.671-1.621l-1.025-1.13a22.73,22.73,0,0,1-1.946-2.55v4.318l.017.008,6.453,3.639.952.533.952-.533Z"
            transform="translate(-162.741 -256.518)"
            fill="#f8b64c"
          />
          <g
            id="Group_2949"
            data-name="Group 2949"
            transform="translate(22.256 28.897)"
            opacity="0.1"
          >
            <g id="Group_2948" data-name="Group 2948">
              <path
                id="Path_8139"
                data-name="Path 8139"
                d="M228.216,290.734l-.019-.006-.032-.011c-.01-.005-.022-.006-.037-.011l3.944,2.929,6.193-3.493h.006l0-4.276c-.01.016-1.47,1.928-1.928,2.5l-1.025,1.131a5.009,5.009,0,0,1-3.671,1.621h-1.569a4.462,4.462,0,0,1-.932-.1,4.84,4.84,0,0,1-.935-.282"
                transform="translate(-228.128 -285.866)"
                fill="#40596b"
              />
            </g>
          </g>
          <g
            id="Group_2950"
            data-name="Group 2950"
            transform="translate(12.976 16.917)"
          >
            <path
              id="Path_8140"
              data-name="Path 8140"
              d="M138.049,166.85c.163,1.877-.748,3.491-2.034,3.6s-2.465-1.319-2.631-3.2.748-3.489,2.036-3.6,2.466,1.318,2.629,3.2"
              transform="translate(-133.364 -163.648)"
              fill="#f8b64c"
            />
            <path
              id="Path_8141"
              data-name="Path 8141"
              d="M335.459,167.268c-.163,1.877-1.342,3.308-2.629,3.2s-2.2-1.725-2.034-3.6,1.342-3.309,2.629-3.2,2.2,1.725,2.034,3.6"
              transform="translate(-311.421 -163.658)"
              fill="#f8b64c"
            />
          </g>
          <path
            id="Path_8142"
            data-name="Path 8142"
            d="M167.013,43.979a20.517,20.517,0,0,1-5.186,11.273L160.8,56.382A5.007,5.007,0,0,1,157.131,58h-1.569a5,5,0,0,1-3.671-1.622l-1.025-1.129a20.606,20.606,0,0,1-5.188-11.273,40.539,40.539,0,0,1,0-7.53,10.815,10.815,0,0,1,21.335,0,40.551,40.551,0,0,1,0,7.53"
            transform="translate(-131.34 -23.85)"
            fill="#ffd15c"
          />
          <path
            id="Path_8143"
            data-name="Path 8143"
            d="M130.549,6.206s.073-7.042,10.229-4.792a17.581,17.581,0,0,1,8.281,6.317s3.042,4.453,1.388,8.2a9.086,9.086,0,0,0-1.048,3.726s-.563-2.373-.9-4.351a9.29,9.29,0,0,0-2.075-4.482l-.032-.039a7.078,7.078,0,0,0-7.114-2.336,12.735,12.735,0,0,0-4.209,2.072s-4.231-2.862-6.323,2.148a27.143,27.143,0,0,0-.8,6.555s-5.824-12.292,2.6-13.018"
            transform="translate(-113.633 0)"
            fill="#40596b"
          />
          <path
            id="Path_8144"
            data-name="Path 8144"
            d="M333.661,327.639a8.736,8.736,0,0,0-.4-2.467,3.75,3.75,0,0,0-2.8-2.039l-3.368-.68v.69h-.01l-.482.272,3.693.753a2.68,2.68,0,0,1,2.029,1.474,2.641,2.641,0,0,1,.283,1.612l-.022.272c-4.424,3.838-5.679,7.3-5.731,7.457a2.677,2.677,0,0,0,.053,2.343,1.615,1.615,0,0,0,1.16.722l.1-1.046a.6.6,0,0,1-.4-.261,1.809,1.809,0,0,1,.073-1.413,15.55,15.55,0,0,1,2.51-4.027,2.242,2.242,0,0,1,2.96.878,15.977,15.977,0,0,1-.261,4.812c-.2.648-.472,1.077-.774,1.192a.51.51,0,0,1-.461-.021l-.261.459-.272.453a1.587,1.587,0,0,0,.795.209,1.437,1.437,0,0,0,.566-.115,2.673,2.673,0,0,0,1.421-1.872c.043-.157,1.014-3.808-.409-9.654m-1.389,2.709a3.057,3.057,0,0,0-.964-.124c.452-.493.952-.994,1.517-1.5.157.731.272,1.432.356,2.092a3.1,3.1,0,0,0-.909-.462"
            transform="translate(-294.731 -289.972)"
            fill="#acb3ba"
          />
          <g
            id="Group_2951"
            data-name="Group 2951"
            transform="translate(33.149 46.82)"
          >
            <path
              id="Path_8145"
              data-name="Path 8145"
              d="M340.679,469.618a.779.779,0,0,1-.913.612c-.418-.083-.12-1.606.3-1.524a.778.778,0,0,1,.613.912"
              transform="translate(-339.565 -468.703)"
              fill="#f8b64c"
            />
            <path
              id="Path_8146"
              data-name="Path 8146"
              d="M367.575,479.2a.776.776,0,0,0,.382,1.029c.39.179,1.037-1.232.647-1.412a.777.777,0,0,0-1.029.383"
              transform="translate(-364.765 -477.76)"
              fill="#f8b64c"
            />
          </g>
          <path
            id="Path_8147"
            data-name="Path 8147"
            d="M99.882,323.133l-.017-.008v-.682l-2.261.465a8.114,8.114,0,0,0-5.795,11.212l.958-.42a7.068,7.068,0,0,1,5.046-9.766l2.555-.526Z"
            transform="translate(-82.23 -289.963)"
            fill="#acb3ba"
          />
          <g
            id="Group_2952"
            data-name="Group 2952"
            transform="translate(13.569 33.16)"
          >
            <path
              id="Path_8148"
              data-name="Path 8148"
              d="M267.431,330.955l-7.717,10.124L256,333.559l.952-.533,6.453-3.64h.01l.01-.01Z"
              transform="translate(-244.569 -329.376)"
              fill="#cdd6e0"
            />
            <path
              id="Path_8149"
              data-name="Path 8149"
              d="M139.413,330.955l7.73,10.124,3.7-7.52-.952-.533-6.453-3.64-.021-.01Z"
              transform="translate(-139.413 -329.376)"
              fill="#cdd6e0"
            />
          </g>
          <path
            id="Path_8150"
            data-name="Path 8150"
            d="M218.251,385.67l2.112-4.811-.911-1.339-.667-1,2.592-5.291.576-1.183.587,1.183v.01l2.594,5.28-.68.995-.911,1.348,2.124,4.811Z"
            transform="translate(-196.952 -334.7)"
            fill="#334a5e"
          />
          <path
            id="Path_8151"
            data-name="Path 8151"
            d="M230.039,378.517l-.68.995-2.49-5.094-2.511,5.1-.667-1,2.592-5.291.576-1.183.587,1.183v.01Z"
            transform="translate(-201.859 -334.7)"
            fill="#2a4356"
          />
          <path
            id="Path_8152"
            data-name="Path 8152"
            d="M91.522,436.47a2.369,2.369,0,1,1-2.37-2.368,2.371,2.371,0,0,1,2.37,2.368"
            transform="translate(-78.313 -390.674)"
            fill="#ff7058"
          />
          <path
            id="Path_8153"
            data-name="Path 8153"
            d="M97.892,443.618a1.591,1.591,0,1,1-1.59-1.591,1.59,1.59,0,0,1,1.59,1.591"
            transform="translate(-85.463 -397.822)"
            fill="#f2f2f2"
          />
          <path
            id="Path_8154"
            data-name="Path 8154"
            d="M101.8,448a1.115,1.115,0,1,1-1.113-1.114A1.113,1.113,0,0,1,101.8,448"
            transform="translate(-89.852 -402.209)"
            fill="#acb3ba"
          />
        </g>
      </g>
    </svg>
  );
};

export default Doctor;
