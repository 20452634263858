import React from "react";

const MedicalStore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="70"
      height="70"
    >
      <g id="medical_store_icon" transform="translate(-20 -190)">
        <rect
          id="Image"
          width="60"
          height="60"
          rx="10"
          transform="translate(20 190)"
          fill="#f1f5f8"
        ></rect>
        <g id="drugstore" transform="translate(6.7 195)">
          <g
            id="Group_3114"
            data-name="Group 3114"
            transform="translate(24.041 19.334)"
          >
            <path
              id="Path_8407"
              data-name="Path 8407"
              d="M94.1,228.119a3.091,3.091,0,0,0,3.088-3.078V197.453c-1.077.2-37.493.2-38.518,0v27.589a3.091,3.091,0,0,0,3.085,3.078Z"
              transform="translate(-58.673 -197.453)"
              fill="#fff"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3115"
            data-name="Group 3115"
            transform="translate(59.21 19.334)"
          >
            <path
              id="Path_8408"
              data-name="Path 8408"
              d="M419.31,204.341v22.383a3.037,3.037,0,0,1-.337,1.393h.265a3.09,3.09,0,0,0,3.085-3.078V197.451l-.1.011c-.342.027-1.382.052-2.911.072v6.808Z"
              transform="translate(-418.973 -197.451)"
              fill="#dddaec"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3116"
            data-name="Group 3116"
            transform="translate(25.903 9.581)"
          >
            <path
              id="Path_8409"
              data-name="Path 8409"
              d="M77.774,102.837l10.059,1-1.261-5.751H80.45a2.679,2.679,0,0,0-2.676,2.67v2.076Z"
              transform="translate(-77.774 -98.091)"
              fill="#ffd772"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3117"
            data-name="Group 3117"
            transform="translate(50.635 9.581)"
          >
            <path
              id="Path_8410"
              data-name="Path 8410"
              d="M332.284,98.091l-1.264,5.751,10.062-1v-2.076a2.681,2.681,0,0,0-2.676-2.67Z"
              transform="translate(-331.02 -98.091)"
              fill="#ffd772"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3118"
            data-name="Group 3118"
            transform="translate(20.3 14.341)"
          >
            <path
              id="Path_8411"
              data-name="Path 8411"
              d="M47.9,154.555a4.6,4.6,0,0,0,9.2,0,4.6,4.6,0,0,0,9.2,0V150.17c0-2.563-2.057-3.4-3.744-3.4H51.629a9.608,9.608,0,0,1-16.657,0H24.045c-1.69,0-3.744.837-3.744,3.4v4.385a4.6,4.6,0,0,0,9.2,0,4.6,4.6,0,0,0,9.2,0,4.6,4.6,0,0,0,9.2,0Z"
              transform="translate(-20.3 -146.77)"
              fill="#eb5468"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3119"
            data-name="Group 3119"
            transform="translate(33.715)"
          >
            <path
              id="Path_8412"
              data-name="Path 8412"
              d="M158.951,14.309a9.563,9.563,0,1,0-1.261-4.746A9.585,9.585,0,0,0,158.951,14.309Z"
              transform="translate(-157.69)"
              fill="#f9f7f8"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3120"
            data-name="Group 3120"
            transform="translate(59.709 14.341)"
          >
            <path
              id="Path_8413"
              data-name="Path 8413"
              d="M424.094,146.868a3.167,3.167,0,0,1,2.748,3.3v4.385a4.6,4.6,0,0,1-2.673,4.165,4.595,4.595,0,0,0,6.517-4.165v-4.385c0-2.563-2.055-3.4-3.741-3.4h-1.862Z"
              transform="translate(-424.094 -146.769)"
              fill="#e5384f"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3121"
            data-name="Group 3121"
            transform="translate(40.926 0)"
          >
            <path
              id="Path_8414"
              data-name="Path 8414"
              d="M233.708,0a9.563,9.563,0,0,1-2.1,18.826,9.608,9.608,0,0,0,10.062-3.551,9.528,9.528,0,0,0,1.9-5.715A9.574,9.574,0,0,0,233.984,0c-.094,0-.185,0-.276,0Z"
              transform="translate(-231.606 0)"
              fill="#dddaec"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3122"
            data-name="Group 3122"
            transform="translate(46.964 30.739)"
          >
            <path
              id="Path_8415"
              data-name="Path 8415"
              d="M302.628,314.434h-7.143a2.021,2.021,0,0,0-2.017,2.012V333.7h11.176V316.446A2.021,2.021,0,0,0,302.628,314.434Z"
              transform="translate(-293.468 -314.434)"
              fill="#b1e4f9"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3123"
            data-name="Group 3123"
            transform="translate(27.925 30.615)"
          >
            <path
              id="Path_8416"
              data-name="Path 8416"
              d="M112.784,326.239V315.181a2.027,2.027,0,0,0-2.022-2.018H100.424a2.024,2.024,0,0,0-2.02,2.018v11.058a2.022,2.022,0,0,0,2.02,2.015h10.338A2.024,2.024,0,0,0,112.784,326.239Z"
              transform="translate(-98.404 -313.163)"
              fill="#b1e4f9"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3124"
            data-name="Group 3124"
            transform="translate(46.967 30.739)"
          >
            <path
              id="Path_8417"
              data-name="Path 8417"
              d="M293.5,316.328h6.845a2.021,2.021,0,0,1,2.017,2.012V333.7h2.312V316.446a2.021,2.021,0,0,0-2.017-2.012H295.51a2.023,2.023,0,0,0-2.014,1.894Z"
              transform="translate(-293.496 -314.434)"
              fill="#75cef8"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3125"
            data-name="Group 3125"
            transform="translate(30.956 33.487)"
          >
            <path
              id="Path_8418"
              data-name="Path 8418"
              d="M131.644,349.013l4.006-3.058-1.639-2.139A2.52,2.52,0,0,0,130,346.871Z"
              transform="translate(-129.487 -342.829)"
              fill="#eb5468"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3126"
            data-name="Group 3126"
            transform="translate(33.115 36.632)"
          >
            <path
              id="Path_8419"
              data-name="Path 8419"
              d="M155.612,374.887l-4.006,3.058,1.639,2.139a2.519,2.519,0,0,0,4.006-3.056Z"
              transform="translate(-151.606 -374.887)"
              fill="#f9f7f8"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3127"
            data-name="Group 3127"
            transform="translate(31.72 33.485)"
          >
            <path
              id="Path_8420"
              data-name="Path 8420"
              d="M139.471,344.834l1.642,2.139.1.129,1.5-1.148-1.639-2.139a2.532,2.532,0,0,0-3.537-.471,2.144,2.144,0,0,0-.223.193C138.279,343.271,138.831,343.995,139.471,344.834Z"
              transform="translate(-137.316 -342.829)"
              fill="#e5384f"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3128"
            data-name="Group 3128"
            transform="translate(35.619 36.632)"
          >
            <path
              id="Path_8421"
              data-name="Path 8421"
              d="M178.777,374.887l-1.5,1.148,1.54,2.01c.781,1.021,1.181,2.059.279,2.934a2.474,2.474,0,0,0,.852-.424,2.516,2.516,0,0,0,.472-3.526Z"
              transform="translate(-177.273 -374.887)"
              fill="#dddaec"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3129"
            data-name="Group 3129"
            transform="translate(27.925 30.615)"
          >
            <path
              id="Path_8422"
              data-name="Path 8422"
              d="M110.761,317.2v11.055a2.024,2.024,0,0,0,2.022-2.015V315.181a2.027,2.027,0,0,0-2.022-2.018H100.424a2.024,2.024,0,0,0-2.02,2.018h9.957A2.384,2.384,0,0,1,110.761,317.2Z"
              transform="translate(-98.404 -313.163)"
              fill="#75cef8"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3130"
            data-name="Group 3130"
            transform="translate(38.312 4.586)"
          >
            <path
              id="Path_8423"
              data-name="Path 8423"
              d="M207.989,53.846v2.877a.273.273,0,0,0,.27.272h3.126a.275.275,0,0,0,.273-.272V53.846h2.88a.273.273,0,0,0,.273-.27V50.457a.273.273,0,0,0-.273-.27h-2.88V47.311a.273.273,0,0,0-.273-.27H208.26a.27.27,0,0,0-.27.27v2.877h-2.883a.27.27,0,0,0-.27.27v3.119a.27.27,0,0,0,.27.27h2.883Z"
              transform="translate(-204.836 -47.041)"
              fill="#eb5468"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3131"
            data-name="Group 3131"
            transform="translate(42.491 4.586)"
          >
            <path
              id="Path_8424"
              data-name="Path 8424"
              d="M253.206,50.187h-2.88V47.311a.273.273,0,0,0-.273-.27H247.68A9.547,9.547,0,0,1,247.9,56.9l-.058.1h2.213a.275.275,0,0,0,.273-.272V53.846h2.88a.273.273,0,0,0,.273-.27V50.457a.273.273,0,0,0-.273-.27Z"
              transform="translate(-247.68 -47.041)"
              fill="#e5384f"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3132"
            data-name="Group 3132"
            transform="translate(49.227 39.423)"
          >
            <path
              id="Path_8425"
              data-name="Path 8425"
              d="M317.5,405.176h-.019a.753.753,0,1,1,0-1.506h.019a.753.753,0,0,1,0,1.506Z"
              transform="translate(-316.729 -403.67)"
              fill="#2b4d66"
            ></path>
          </g>
          <g
            id="Group_3133"
            data-name="Group 3133"
            transform="translate(58.15 36.803)"
          >
            <path
              id="Path_8426"
              data-name="Path 8426"
              d="M408.106,389.834h7.606a.3.3,0,0,0,.3-.3V376.938a.3.3,0,0,0-.3-.3h-7.3a.3.3,0,0,0-.3.3Z"
              transform="translate(-408.106 -376.637)"
              fill="#ffd772"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3134"
            data-name="Group 3134"
            transform="translate(21.155 40.599)"
          >
            <path
              id="Path_8427"
              data-name="Path 8427"
              d="M33.464,415.575a4.4,4.4,0,0,1,4.389,4.38v5.021h-8.48a.3.3,0,0,1-.3-.3v-4.72A4.4,4.4,0,0,1,33.464,415.575Z"
              transform="translate(-29.072 -415.575)"
              fill="#a7e9a6"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3135"
            data-name="Group 3135"
            transform="translate(21.155 40.599)"
          >
            <path
              id="Path_8428"
              data-name="Path 8428"
              d="M35.049,419.892v5.018H29.073v.063h8.782v-5.021a4.4,4.4,0,0,0-4.389-4.38,4.438,4.438,0,0,0-1.186.162l-.124.039c.041.014.083.03.124.047a4.4,4.4,0,0,1,2.77,4.072Z"
              transform="translate(-29.073 -415.573)"
              fill="#7fdbae"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3136"
            data-name="Group 3136"
            transform="translate(60.589 39.423)"
          >
            <path
              id="Path_8429"
              data-name="Path 8429"
              d="M435.4,405.176h-1.523a.753.753,0,1,1,0-1.506H435.4a.753.753,0,1,1,0,1.506Z"
              transform="translate(-433.121 -403.67)"
              fill="#2b4d66"
            ></path>
          </g>
          <g
            id="Group_3137"
            data-name="Group 3137"
            transform="translate(60.589 42.747)"
          >
            <path
              id="Path_8430"
              data-name="Path 8430"
              d="M435.4,439.115h-1.523a.753.753,0,1,1,0-1.506H435.4a.753.753,0,1,1,0,1.506Z"
              transform="translate(-433.121 -437.609)"
              fill="#2b4d66"
            ></path>
          </g>
          <g
            id="Group_3138"
            data-name="Group 3138"
            transform="translate(60.589 46.059)"
          >
            <path
              id="Path_8431"
              data-name="Path 8431"
              d="M435.4,473.083h-1.523a.753.753,0,1,1,0-1.506H435.4a.753.753,0,1,1,0,1.506Z"
              transform="translate(-433.121 -471.577)"
              fill="#2b4d66"
            ></path>
          </g>
          <g
            id="Group_3139"
            data-name="Group 3139"
            transform="translate(64.084 36.803)"
          >
            <path
              id="Path_8432"
              data-name="Path 8432"
              d="M468.976,376.637v13.2h1.671a.3.3,0,0,0,.3-.3V376.938a.3.3,0,0,0-.3-.3Z"
              transform="translate(-468.976 -376.637)"
              fill="#fbba58"
              fill-rule="evenodd"
            ></path>
          </g>
          <g
            id="Group_3140"
            data-name="Group 3140"
            transform="translate(53.755 9.581)"
          >
            <path
              id="Path_8433"
              data-name="Path 8433"
              d="M363.026,98.091a2.679,2.679,0,0,1,2.676,2.67v2.076h4.265v-2.076a2.681,2.681,0,0,0-2.676-2.67Z"
              transform="translate(-363.026 -98.091)"
              fill="#fbba58"
              fill-rule="evenodd"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MedicalStore;
