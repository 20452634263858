import React, { useState } from "react";
import tw from "tailwind-styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import LiveDoctorSubsModel from "../LiveDoctorSubsModel";
import InfoModel from "../InfoModel";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";
import Config from "../../Config";
// import required modules
import { useDispatch, useSelector } from "react-redux";
import { Autoplay, Pagination } from "swiper";
import Images from "../../Images";
import { useNavigate } from "react-router-dom";

const Carousel = () => {
  const [openModel, setOpenModel] = useState(false);
  const [openInfoModel, setOpenInfoModel] = useState(false);
  const [infoModelMsg, setInfoModelMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.UserReducer.user);
  const navigate = useNavigate();

  const openModelFunc = () => {
    setOpenModel(true);
  };
  const closeModelFunc = () => setOpenModel(false);
  const closeInfoModelFunc = () => setOpenInfoModel(false);

  const subscribeEventApi = async () => {
    setIsLoading(true);

    const body = new FormData();
    body.append("mobile", user?.mobile);

    const resp = await axios.post(Config.RegisterForEvent, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
    if (resp.data.status === "OK") {
      // setOpenModel(false);
      // setInfoModelMsg(resp.data.msg);
      // setOpenInfoModel(true);
      navigate("/get-appointment");
    } else {
      setOpenModel(false);
      setInfoModelMsg(resp.data.msg);
      setOpenInfoModel(true);
    }
    setIsLoading(false);
  };

  return (
    <Wrapper>
      {openInfoModel && (
        <InfoModel
          setOpenModel={setOpenInfoModel}
          closeModelFunc={closeInfoModelFunc}
          msg={infoModelMsg}
        />
      )}

      {openModel && (
        <LiveDoctorSubsModel
          setOpenModel={setOpenModel}
          isLoading={isLoading}
          closeModelFunc={closeModelFunc}
          registerHandler={subscribeEventApi}
        />
      )}
      <Swiper
        // onClick={openModelFunc}
        pagination={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay,Pagination]}
        spaceBetween={1}
        className="mySwiper"
      >
        <SwiperSlide onClick={() => navigate("/community")}>
          <img
            src={Config.ImageBasePath + "community.png"}
            alt="community image"
          />
        </SwiperSlide>
        {/* <SwiperSlide onClick={openModelFunc}>
          <img src={Config.ImageBasePath + "event.png"} alt="banner image" />
        </SwiperSlide> */}
      </Swiper>
    </Wrapper>
  );
};

const Wrapper = tw.div`
h-36 md:h-48 rounded-lg overflow-hidden shadow-md`;

export default Carousel;
