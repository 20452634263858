import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import CallIcon from "../Components/SVGs/CallIcon";
import LocationIcon from "../Components/SVGs/LocationIcon";
import VideoFilledIcon from "../Components/SVGs/VideoFilledIcon";
import Doctor from "../Components/SVGs/Doctor";
import MedicalStore from "../Components/SVGs/MedicalStore";
import ReactWhatsapp from "react-whatsapp";
import Config from "../Config";
import axios from "axios";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MedicalStores = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [list, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.UserReducer.user);

  const WhatsAppHandler = (mobile) => {
    window.open(`https://wa.me/${mobile}`, "_blank");
  };

  const fetchPharmaciesList = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      
      body.append("lang", i18n.language);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.GetPharmaciesList, body, axiosConfig);
      setLoading(false);
      if (res.data.status === "OK") {
        setData(res.data.list);
      } else {
        dispatch(Remove_User());
        window.location.replace(`/`);
      }
    }
  };

  useEffect(() => {
    fetchPharmaciesList();
  }, []);

  return (
    <Container>
      <DetailNavbar title={t("MedicalStores")} />

      <Wrapper>
        {/* loader */}
        {loading && (
          <div
            className={`w-full ${loading && "h-96"} grid place-items-center`}
          >
            <img
              src={Images.GreenLoader}
              alt="loading..."
              className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
            />
          </div>
        )}

        {!loading &&
          list.map((item, index) => (
            <SingleItem
              key={index}
              pharmacyId={item.id}
              doctorName={item.name}
              designation={item.specialist}
              location={item.address}
              handler={WhatsAppHandler}
              mobile={item.msisdn}
            />
          ))}
      </Wrapper>
    </Container>
  );
};

const SingleItem = ({
  pharmacyId,
  doctorName,
  designation,
  location,
  handler,
  mobile,
}) => (
  <SingleItemWrapper>
    <MedicalStore />
    <TextWrapper>
      <Title>{doctorName}</Title>
      <Designation>{designation}</Designation>
      <Location>
        <LocationIcon />
        <p>{location}</p>
      </Location>
    </TextWrapper>
    <Icons>
      <WhatsAppBtn title="WhatsApp Call" onClick={() => handler(mobile)}>
        <img src={Images.WhatsApp} alt="create icon" />
      </WhatsAppBtn>
      <Link to={`/medicine-list/${pharmacyId}`}>
        <img src={Images.Meidicine} alt="create icon" />
      </Link>
      {/* <VideoFilledIcon /> */}
    </Icons>
  </SingleItemWrapper>
);

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;

const Wrapper = tw.div`flex flex-col space-y-5 mt-5 px-4`;

const SingleItemWrapper = tw.div`
w-full bg-white shadow-md p-3 rounded-lg flex space-x-2 `;
const WhatsAppBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const TextWrapper = tw.div`w-full`;
const Title = tw.h3`font-medium text-gray-900`;
const Designation = tw.div`text-sm text-gray-500`;
const Location = tw.div`flex space-x-1 items-center text-sm text-gray-500`;
const Icons = tw.div`flex flex-col space-y-2`;

export default MedicalStores;
