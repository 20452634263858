import React from "react";

const Bones = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50"
      height="50"
    >
      <g id="bones_1" transform="translate(-48 -424)">
        <rect
          id="Rectangle_1564"
          data-name="Rectangle 1564"
          width="50"
          height="50"
          transform="translate(48 424)"
          fill="#fff"
        ></rect>
        <g id="bones" transform="translate(55.001 430.999)">
          <path
            id="Path_8130"
            data-name="Path 8130"
            d="M244.311,19.2l-5.258,5.258a1.2,1.2,0,0,0-.163,1.489,4.251,4.251,0,1,1-7.847,2.3,4.251,4.251,0,1,1,2.3-7.847,1.2,1.2,0,0,0,1.489-.163l5.258-5.258a1.2,1.2,0,0,1,1.692,0l2.527,2.527A1.2,1.2,0,0,1,244.311,19.2Z"
            transform="translate(-209.756 -13.532)"
            fill="#caf1fc"
          ></path>
          <path
            id="Path_8131"
            data-name="Path 8131"
            d="M235.31,95.748a4.26,4.26,0,0,1-4.268-4.224,4.251,4.251,0,1,1,.079-8.5,1.188,1.188,0,0,1,.94,1.873,4.3,4.3,0,0,0,2.669,6.542,1.2,1.2,0,0,1,.939.943,4.112,4.112,0,0,0,.639,1.5A1.2,1.2,0,0,1,235.31,95.748Z"
            transform="translate(-209.789 -76.784)"
            fill="#e3f7fc"
          ></path>
          <path
            id="Path_8132"
            data-name="Path 8132"
            d="M31.219,238.3a4.252,4.252,0,0,1-5.276.59,1.2,1.2,0,0,0-1.489.163L19.2,244.312a1.2,1.2,0,0,1-1.692,0l-2.527-2.527a1.2,1.2,0,0,1,0-1.692l5.258-5.258a1.2,1.2,0,0,0,.163-1.489,4.251,4.251,0,1,1,7.847-2.3A4.255,4.255,0,0,1,31.219,238.3Z"
            transform="translate(-13.534 -209.755)"
            fill="#caf1fc"
          ></path>
          <path
            id="Path_8133"
            data-name="Path 8133"
            d="M24.324,234.383a1.192,1.192,0,0,1-.166,1.483l-4.338,4.339a2.5,2.5,0,0,0-.448,2.919,1.137,1.137,0,0,1-1.872,1.237l-2.527-2.527a1.2,1.2,0,0,1,0-1.692l5.258-5.259a1.2,1.2,0,0,0,.163-1.489,4.255,4.255,0,0,1,3.935-6.507,1.2,1.2,0,0,1,.725,2.073A4.277,4.277,0,0,0,24.324,234.383Z"
            transform="translate(-13.53 -209.804)"
            fill="#e3f7fc"
          ></path>
          <path
            id="Path_8134"
            data-name="Path 8134"
            d="M12.39,7.642v-1.3a.6.6,0,1,1,1.2,0v1.3a.6.6,0,1,1-1.2,0ZM35.824,4.408a.6.6,0,0,0-.848,0c-7.461,7.461-7.051,6.946-7.051,7.3a.6.6,0,0,0,.176.424,3.663,3.663,0,1,1-6.253,2.618.6.6,0,1,0-1.2.009,4.863,4.863,0,1,0,8.7-3.025l6.477-6.477a.6.6,0,0,0,0-.849ZM18.688,13.93a.6.6,0,0,0,0-.848A3.663,3.663,0,1,1,23.869,7.9a.6.6,0,0,0,.848,0l6.875-6.875a.6.6,0,0,0-.848-.848L24.267,6.654A4.863,4.863,0,0,0,17.84,13.93a.6.6,0,0,0,.849,0Zm-1.377,8.142a.6.6,0,0,0,0,.848A3.663,3.663,0,1,1,12.13,28.1a.6.6,0,0,0-.848,0L4.406,34.977a.6.6,0,1,0,.848.848l6.477-6.477a4.863,4.863,0,0,0,6.427-7.276A.6.6,0,0,0,17.311,22.072ZM7.9,23.87a3.663,3.663,0,1,1,6.253-2.618.6.6,0,1,0,1.2-.009,4.863,4.863,0,1,0-8.7,3.024L.175,30.746a.6.6,0,0,0,.848.848L7.9,24.719a.6.6,0,0,0,0-.849Zm5.657-9.465.772.772a.6.6,0,0,0,.848-.848l-.772-.772a.6.6,0,0,0-.848.848Zm8.887,7.19L17.149,16.3a.6.6,0,1,0-.848.848l5.294,5.294a.6.6,0,1,0,.848-.848ZM8.73,9.331a.6.6,0,0,0,.424-1.024L7.924,7.077a.6.6,0,0,0-.848.848C8.4,9.252,8.407,9.331,8.73,9.331Zm-2.391,4.26h1.3a.6.6,0,1,0,0-1.2h-1.3a.6.6,0,0,0,0,1.2ZM27.693,26.846a.6.6,0,1,0-.848.848C28.171,29.021,28.176,29.1,28.5,29.1a.6.6,0,0,0,.424-1.024Zm-4.684.914a.6.6,0,0,0-.6.6v1.3a.6.6,0,1,0,1.2,0v-1.3A.6.6,0,0,0,23.009,27.76Zm6.65-5.35h-1.3a.6.6,0,1,0,0,1.2h1.3a.6.6,0,0,0,0-1.2Z"
            transform="translate(0 0)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Bones;
