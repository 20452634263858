import React from "react";
import tw from "tailwind-styled-components";
import CovidIcon from "../SVGs/CovidIcon";
import DiabetesIcon from "../SVGs/DiabetesIcon";
import WellnessIcon from "../SVGs/WellnessIcon";
import MindIcon from "../SVGs/MindIcon";
import ImmuneIcon from "../SVGs/ImmuneIcon";
import YogaIcon from "../SVGs/YogaIcon";
import Bones from "../SVGs/Bones";
import ViewAll from "../SVGs/ViewAll";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Categories = () => {
  const { t, i18n } = useTranslation();

  const list = [
    // { name: t("LiveVideo"), icon: <VideoIcon /> },
    { param: "Covid", name: t("Covid"), icon: <CovidIcon /> },
    { param: "Diabetes", name: t("Diabetes"), icon: <DiabetesIcon /> },
    { param: "Wellness", name: t("Wellness"), icon: <WellnessIcon /> },
    { param: "Mind", name: t("Mind"), icon: <MindIcon /> },
    { param: "Immune", name: t("Immune"), icon: <ImmuneIcon /> },
    { param: "Yoga", name: t("Yoga"), icon: <YogaIcon /> },
    { param: "Bones", name: t("Bones"), icon: <Bones /> },
    { param: "Covid", name: t("ViewDetails"), icon: <ViewAll /> },
  ];

  return (
    <Wrapper>
      <Title>{t("Catagories")}</Title>

      <Container>
        {list.map((category, index) => (
          <SingleCategory {...category} key={index} />
        ))}
      </Container>
    </Wrapper>
  );
};

const SingleCategory = ({ icon, name, param }) => (
  <Link to={`/categories/${param.toLowerCase()}`}>
    <SingleCategoryWrapper>
      <Figure>{icon}</Figure>
      <CategoryName>{name}</CategoryName>
    </SingleCategoryWrapper>
  </Link>
);

const Wrapper = tw.div`w-full border border-gray-300 rounded-lg p-5 `;
const Container = tw.div`grid grid-cols-4 gap-5 items-center text-center mt-5 `;
const Title = tw.h3`text-gray-900 font-bold  text-lg  `;
const SingleCategoryWrapper = tw.div`flex flex-col items-center justify-center cursor-pointer`;
const Figure = tw.figure`shadow rounded-md overflow-hidden`;
const CategoryName = tw.p`text-sm text-gray-600 mt-1.5`;

export default Categories;
