import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import CallIcon from "../Components/SVGs/CallIcon";
import LocationIcon from "../Components/SVGs/LocationIcon";
import VideoFilledIcon from "../Components/SVGs/VideoFilledIcon";
import Doctor from "../Components/SVGs/Doctor";
import Config from "../Config";
import axios from "axios";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { toast } from "react-toastify";
import moment from "moment";
import AddReplyModal from "../Components/AddReplyModal";
import EditReplyModal from "../Components/EditReplyModal";
import DeleteReplyModal from "../Components/DeleteReplyModal";

import { BsThreeDotsVertical } from "react-icons/bs";

const CommunityDetail = () => {
  const { id } = useParams();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const user = useSelector((state) => state.UserReducer.user);
  const [editLoading, setEditLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeAnswerId, setActiveId] = useState("");
  const [activeQues, setActiveQues] = useState("");

  const deleteReplyAPI = async () => {
    setDeleteLoading(true);

    const body = new FormData();
    body.append("mobile", user?.mobile);
    body.append("answerId", activeAnswerId);
    body.append("lang", i18n.language);
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(Config.DeleteReply, body, axiosConfig);

    if (res.data.status === "OK") {
      toast(res.data.msg || "Your Question Deleted Successfully", {
        type: "success",
      });
      setShowDeleteModal(false);
      fetchCommunityDetail();
    } else {
      console.log("error occured");
      toast(res.data.msg || "An Error Occured", { type: "error" });
    }
    setDeleteLoading(false);
  };

  const openDeleteModal = (item) => {
    setActiveId(item?.answerId);
    setShowDeleteModal(true);
  };

  const openEditModal = (item) => {
    console.log(item);
    setActiveQues(item?.questionReply);
    setActiveId(item?.answerId);
    setShowEditModal(true);
  };

  const fetchCommunityDetail = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("lang", i18n.language);
      body.append("questionId", id);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(
        Config.GetCommunityDetailList,
        body,
        axiosConfig
      );

      setLoading(false);
      if (res.data.status === "OK") {
        setData(res.data.data);
      } else {
        toast(res.data.msg || "An Error Occured", { type: "error" });
        // dispatch(Remove_User());
        // window.location.replace(`/`);
      }
    } else {
      // dispatch(Remove_User());
    }
  };

  useEffect(() => {
    fetchCommunityDetail();
  }, []);

  const replyFuntion = async (formVal, setFormVal) => {
    setReplyLoading(true);

    const body = new FormData();
    body.append("mobile", user?.mobile);
    body.append("questionReply", formVal?.reply);
    body.append("name", user?.firstName);
    body.append("questionId", id);
    body.append("lang", i18n.language);
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(Config.Reply, body, axiosConfig);

    if (res.data.status === "OK") {
      toast(res.data.msg || "Your Question Added Successfully", {
        type: "success",
      });
      setShowReplyModal(false);
      fetchCommunityDetail();
      setFormVal({
        reply: "",
        name: "",
      });
    } else {
      console.log("error occured");
      toast(res.data.msg || "An Error Occured", { type: "error" });
    }
    setReplyLoading(false);
  };

  const editReply = async (formVal, setFormVal) => {
    setEditLoading(true);

    const body = new FormData();

    body.append("mobile", user?.mobile);
    body.append("reply", formVal?.reply);
    body.append("answerId", activeAnswerId);
    body.append("lang", i18n.language);

    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(Config.EditReply, body, axiosConfig);

    if (res.data.status === "OK") {
      toast(res.data.msg || "Your Answer Edited Successfully", {
        type: "success",
      });
      setShowEditModal(false);
      fetchCommunityDetail();
      setFormVal({
        reply: "",
      });
    } else {
      console.log("error occured");
      toast(res.data.msg || "An Error Occured", { type: "error" });
    }
    setEditLoading(false);
  };

  return (
    <Container>
      <AddReplyModal
        showAddModal={showReplyModal}
        setShowAddModal={setShowReplyModal}
        addLoading={replyLoading}
        addQuestion={replyFuntion}
      />
      <EditReplyModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        Loading={editLoading}
        editReply={editReply}
        activeQues={activeQues}
      />
      <DeleteReplyModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        Loading={deleteLoading}
        deleteReply={deleteReplyAPI}
      />

      <DetailNavbar title={t("Community")} route={"/community"} />
      <Wrapper>
        <Title>{t("CommunityTitle")}</Title>

        {loading && <LoadingComp loading={loading} />}
        {!loading && (
          <>
            <SingleThread data={data} t={t} />

            <div className="flex items-center justify-between">
              <CardBtn>
                <span>
                  {data?.answerCount} {t("Answers")}
                </span>{" "}
                <FaChevronRight className="w-2.5 h-2.5 mt-1 rotate-90" />
              </CardBtn>

              <CardBtn
                className="border border-custom-orange px-4 cursor-pointer"
                onClick={() => setShowReplyModal(true)}
              >
                {t("Reply")}
              </CardBtn>
            </div>

            {data?.answers?.length > 0 && (
              <Answers>
                {data?.answers?.map((item, index) => (
                  <SingleAnswer
                    key={item.id}
                    item={item}
                    t={t}
                    index={index}
                    openEditModal={openEditModal}
                    openDeleteModal={openDeleteModal}
                  />
                ))}
              </Answers>
            )}
          </>
        )}
      </Wrapper>
    </Container>
  );
};

const SingleThread = ({ data, t }) => (
  <>
    <SingleCard>
      <CardWrapper>
        <Name>{data?.name}</Name>
        <Date>{moment(data?.creationDate).format("DD/MM/yyyy HH:mm")}</Date>
      </CardWrapper>
      <CardTitle>
        <i>{data?.question}</i>
      </CardTitle>
      <CardWrapper>
        <Info>
          {" "}
          {/* {t("AskedFor")} {data?.gender}, {data?.age} {t("Years")} */}
          {data?.gender}, {data?.age} {t("Years")}
        </Info>
      </CardWrapper>
    </SingleCard>
  </>
);

const SingleAnswer = ({ item, t, index, openEditModal, openDeleteModal }) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <>
      <SingleAnswerCard $first={index == 0}>
        {showOptions && (
          <Options
            item={item}
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
          />
        )}
        <UpperWrapper>
          <div className="shrink-0">
            {item?.photo ? (
              <img src={item?.photo} alt="" />
            ) : (
              <Doctor width={50} />
            )}
          </div>
          <UpperTextWrapper className="mr-auto ml-3">
            <CardWrapper className="justify-end mt-0">
              <CardDoctorTitle>{item?.name}</CardDoctorTitle>
            </CardWrapper>
            <Info>{moment(item?.creationDate).format("DD/MM/yyyy HH:mm")}</Info>

            <Comment>{item?.questionReply}</Comment>
          </UpperTextWrapper>
          {item?.isMe && (
            <BsThreeDotsVertical
              className="w-4 h-4 ml-auto text-gray-600 cursor-pointer mt-2 shrink-0"
              onClick={() => setShowOptions((prev) => !prev)}
            />
          )}
        </UpperWrapper>
      </SingleAnswerCard>
    </>
  );
};

const Options = ({ openEditModal, item, openDeleteModal }) => (
  <>
    <OptionWrapper>
      <Option onClick={() => openEditModal(item)}>Edit</Option>
      <Option onClick={() => openDeleteModal(item)}>Delete</Option>
    </OptionWrapper>
  </>
);

const LoadingComp = ({ loading }) => (
  <div className={`w-full ${loading && "h-96"} grid place-items-center`}>
    <img
      src={Images.GreenLoader}
      alt="loading..."
      className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
    />
  </div>
);

const OptionWrapper = tw.div`bg-white absolute top-4 right-10 rounded-md shadow-md z-10 border`;
const Option = tw.div`py-3.5 px-10 text-xs border-b border-gray-100 hover:bg-gray-100`;

const Container = tw.section`Container  border relative bg-gray-100 shadow-md  h-screen overflow-y-auto  overflow-x-hidden  `;
const Wrapper = tw.div`flex flex-col space-y-5 mt-5 px-4 h-full`;
const Title = tw.h1`text-lg text-gray-700 font-medium`;

const UpperWrapper = tw.article`flex items-start`;
const UpperTextWrapper = tw.div``;

const Answers = tw.section`bg-white text-left p-4 pt-2 my-16  text-center shadow-lg border  relative rounded-lg shadow-md   `;
const SingleCard = tw.section`bg-white text-left p-4 mb-8 border-b border-gray-200  text-center shadow-lg   relative rounded-lg shadow-md   `;
const CardTitle = tw.h3`text-base  font-medium text-gray-700 text-left`;
const Date = tw.p`text-xs text-gray-400`;
const Name = tw.h3`text-sm text-gray-500 text-left`;
const Info = tw.p`text-xs text-gray-500`;

const SingleAnswerCard = tw.section`
${(p) => (p.$first ? "" : "border-t border-gray-200")}
text-left py-5 pb-6  relative     `;
const CardDoctorTitle = tw.h3`text-base  mt-2 font-medium text-gray-700 text-left mb-1`;
const CardWrapper = tw.div`mt-2 flex items-center justify-between`;
const CardBtn = tw.p`p-1 rounded-md   text-custom-orange font-medium  text-xs   flex items-center  space-x-0.5`;
const Comment = tw.p`text-xs text-gray-500 mt-2 `;

export default CommunityDetail;
