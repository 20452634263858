import React from "react";
import tw from "tailwind-styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";
import Mindspace from "../SVGs/Mindspace";
import Images from "../../Images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const HealthArticles = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t("HealthArticles")}</Title>

        {/* <Link to="/health-articles">
          <ViewAll>View All</ViewAll>
        </Link> */}
      </TitleWrapper>

      <Swiper
        slidesPerView={2.5}
        spaceBetween={20}
        scrollbar={true}
        navigation={true}
        modules={[Keyboard, Scrollbar, Navigation, Pagination]}
        className="VideoSwiper mt-3"
      >
        <SwiperSlide>
          <Link to="/health-articles/mind">
            <SingleArticle
              img={Images.Article}
              article={t("MindArticles")}
              // desc={"10 Articles"}
            />
          </Link>
        </SwiperSlide>

        <SwiperSlide>
          <Link to="/health-articles/cancer">
            <SingleArticle
              img={Images.Article2}
              article={t("CancerArticles")}
              // desc={"15 Articles"}
            />
          </Link>
        </SwiperSlide>

        <SwiperSlide>
          <Link to="/health-articles/yoga">
            <SingleArticle
              img={Images.Article3}
              article={t("YogaArticles")}
              // desc={"17 Articles"}
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/health-articles/exercise">
            <SingleArticle
              img={Images.Article5}
              article={t("Exercise")}
              // desc={"17 Articles"}
            />
          </Link>
        </SwiperSlide>
      </Swiper>
    </Wrapper>
  );
};

const SingleArticle = ({ img, article, desc }) => (
  <SingleArticleWrapper>
    <Figure>
      <img src={img} alt={article} />
    </Figure>
    <TextWrapper>
      <TipDesc>{desc}</TipDesc>
      <Tip>{article}</Tip>
    </TextWrapper>
  </SingleArticleWrapper>
);

const Wrapper = tw.div`w-full  `;
const SingleArticleWrapper = tw.div`w-full flex flex-col space-y-2  rounded-lg`;
const Figure = tw.figure`rounded-lg overflow-hidden`;
const TextWrapper = tw.div``;

const TitleWrapper = tw.div`w-full mb-4 flex items-center justify-between`;
const Title = tw.h3`text-gray-900 font-bold  text-lg  `;
const ViewAll = tw.p`text-blue-500 text-sm cursor-pointer `;

const Tip = tw.p`text-gray-800 font-medium text-sm mt-1`;
const TipDesc = tw.p`text-gray-700 text-xs `;

export default HealthArticles;
