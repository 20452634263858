import React from "react";

const ImmuneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50"
      height="50"
    >
      <g id="immune_1" transform="translate(-278 -522)">
        <rect
          id="Rectangle_1561"
          data-name="Rectangle 1561"
          width="50"
          height="50"
          transform="translate(278 522)"
          fill="#fff"
        ></rect>
        <g
          id="immune-system_1_"
          data-name="immune-system (1)"
          transform="translate(279.112 529)"
        >
          <g
            id="Group_3050"
            data-name="Group 3050"
            transform="translate(6.399 25.388)"
          >
            <path
              id="Path_8274"
              data-name="Path 8274"
              d="M19.469,362.521a1.879,1.879,0,0,0-2.768-1.476.781.781,0,0,1-.752,0,1.879,1.879,0,0,0-1.867,3.259.744.744,0,0,1,.368.578,1.879,1.879,0,0,0,3.749,0,.746.746,0,0,1,.369-.58A1.877,1.877,0,0,0,19.469,362.521Z"
              transform="translate(-13.173 -360.817)"
              fill="#aad880"
            ></path>
          </g>
          <g
            id="Group_3052"
            data-name="Group 3052"
            transform="translate(8.728 25.388)"
          >
            <g id="Group_3051" data-name="Group 3051">
              <path
                id="Path_8275"
                data-name="Path 8275"
                d="M50.31,362.522a1.877,1.877,0,0,0-2.7-1.511,1.876,1.876,0,0,1,.148,3.294.746.746,0,0,0-.369.58,1.88,1.88,0,0,1-1.048,1.558,1.875,1.875,0,0,0,2.7-1.558.746.746,0,0,1,.369-.58A1.878,1.878,0,0,0,50.31,362.522Z"
                transform="translate(-46.343 -360.818)"
                fill="#80cb8e"
              ></path>
            </g>
          </g>
          <g
            id="Group_3053"
            data-name="Group 3053"
            transform="translate(30.03 31.644)"
          >
            <path
              id="Path_8276"
              data-name="Path 8276"
              d="M343.365,453.711a1.162,1.162,0,0,0,.427-1.893.483.483,0,0,1-.123-.448,1.162,1.162,0,0,0-2.25-.58.46.46,0,0,1-.284.314,1.162,1.162,0,0,0,.614,2.237.462.462,0,0,1,.406.125A1.161,1.161,0,0,0,343.365,453.711Z"
              transform="translate(-340.355 -449.942)"
              fill="#f4dd45"
            ></path>
          </g>
          <g
            id="Group_3054"
            data-name="Group 3054"
            transform="translate(31.482 31.644)"
          >
            <path
              id="Path_8277"
              data-name="Path 8277"
              d="M363.192,452.392a1.159,1.159,0,0,0-.3-.575.483.483,0,0,1-.123-.448,1.162,1.162,0,0,0-1.872-1.16,1.157,1.157,0,0,1,.394,1.16.483.483,0,0,0,.123.448,1.161,1.161,0,0,1-.1,1.7,1.162,1.162,0,0,0,1.879-1.127Z"
              transform="translate(-360.895 -449.942)"
              fill="#eab14d"
            ></path>
          </g>
          <g
            id="Group_3055"
            data-name="Group 3055"
            transform="translate(36.263 8.945)"
          >
            <path
              id="Path_8278"
              data-name="Path 8278"
              d="M431.035,132.732l-.566-.953a.832.832,0,0,1-.075-.67,1.954,1.954,0,0,0-.932-2.286.837.837,0,0,1-.417-.525l-.227-.891a.3.3,0,0,0-.587,0l-.227.891a.838.838,0,0,1-.417.525,1.954,1.954,0,0,0-.932,2.286.831.831,0,0,1-.075.67l-.566.953a.3.3,0,0,0,.415.415l1.036-.616a.8.8,0,0,1,.6-.094,1.961,1.961,0,0,0,.92,0,.8.8,0,0,1,.6.094l1.036.616A.3.3,0,0,0,431.035,132.732Z"
              transform="translate(-425.971 -127.179)"
              fill="#bed8fb"
            ></path>
          </g>
          <g
            id="Group_3057"
            data-name="Group 3057"
            transform="translate(38.061 8.945)"
          >
            <g id="Group_3056" data-name="Group 3056">
              <path
                id="Path_8279"
                data-name="Path 8279"
                d="M454.854,132.732l-.566-.953a.832.832,0,0,1-.075-.67,1.954,1.954,0,0,0-.932-2.286.838.838,0,0,1-.417-.525l-.227-.891a.3.3,0,0,0-.587,0l-.227.891a.814.814,0,0,1-.236.393.85.85,0,0,0,.374.4,1.954,1.954,0,0,1,.932,2.286.831.831,0,0,0,.075.67l.234.394a.833.833,0,0,1,.2.085l1.036.616A.3.3,0,0,0,454.854,132.732Z"
                transform="translate(-451.589 -127.179)"
                fill="#9dc6fb"
              ></path>
            </g>
          </g>
          <g
            id="Group_3058"
            data-name="Group 3058"
            transform="translate(29.85 1.864)"
          >
            <circle
              id="Ellipse_27"
              data-name="Ellipse 27"
              cx="3.78"
              cy="3.78"
              r="3.78"
              fill="#c8ea73"
            ></circle>
          </g>
          <g
            id="Group_3059"
            data-name="Group 3059"
            transform="translate(30.643 2.374)"
          >
            <path
              id="Path_8280"
              data-name="Path 8280"
              d="M350.79,33.827a3.779,3.779,0,0,1-4.883,5.585,3.779,3.779,0,1,0,4.883-5.585Z"
              transform="translate(-345.907 -33.827)"
              fill="#aad880"
            ></path>
          </g>
          <g
            id="Group_3060"
            data-name="Group 3060"
            transform="translate(7.809 3.105)"
          >
            <ellipse
              id="Ellipse_28"
              data-name="Ellipse 28"
              cx="15.29"
              cy="14.877"
              rx="15.29"
              ry="14.877"
              fill="#fff9e2"
            ></ellipse>
          </g>
          <g
            id="Group_3061"
            data-name="Group 3061"
            transform="translate(12.135 6.604)"
          >
            <path
              id="Path_8281"
              data-name="Path 8281"
              d="M103.139,93.208a14.846,14.846,0,0,1-20.908,20.908,14.846,14.846,0,1,0,20.908-20.908Z"
              transform="translate(-82.231 -93.208)"
              fill="#f9efaf"
            ></path>
          </g>
          <g
            id="Group_3062"
            data-name="Group 3062"
            transform="translate(13.074 7.087)"
          >
            <path
              id="Path_8282"
              data-name="Path 8282"
              d="M114.416,101.744l-1.446-1.127a.633.633,0,0,0-.778,0l-1.446,1.127a7.282,7.282,0,0,1-6.512,1.246l-1.225-.357a.317.317,0,0,0-.405.3v7.023a12.665,12.665,0,0,0,5.292,10.3l4.244,3.039a.76.76,0,0,0,.885,0l4.244-3.039a12.665,12.665,0,0,0,5.292-10.3v-7.023a.317.317,0,0,0-.405-.3l-1.225.357A7.283,7.283,0,0,1,114.416,101.744Z"
              transform="translate(-102.602 -100.484)"
              fill="#e28086"
            ></path>
          </g>
          <g
            id="Group_3063"
            data-name="Group 3063"
            transform="translate(20.973 9.227)"
          >
            <path
              id="Path_8283"
              data-name="Path 8283"
              d="M224.6,130.931l-1.225.357a7.275,7.275,0,0,1-1.05.223v5.451a12.665,12.665,0,0,1-5.292,10.3l-4.251,3.044,1.8,1.291a.76.76,0,0,0,.885,0l4.244-3.039a12.665,12.665,0,0,0,5.292-10.3v-7.023A.317.317,0,0,0,224.6,130.931Z"
              transform="translate(-212.777 -130.918)"
              fill="#dd636e"
            ></path>
          </g>
          <g
            id="Group_3064"
            data-name="Group 3064"
            transform="translate(23.164 7.087)"
          >
            <path
              id="Path_8284"
              data-name="Path 8284"
              d="M253.247,102.64l-1.225.357a7.283,7.283,0,0,1-6.512-1.246l-1.446-1.127a.632.632,0,0,0-.471-.128v22.944a.759.759,0,0,0,.525-.137l4.244-3.039a12.665,12.665,0,0,0,5.292-10.3v-7.023A.317.317,0,0,0,253.247,102.64Z"
              transform="translate(-243.592 -100.492)"
              fill="#dd636e"
            ></path>
          </g>
          <g
            id="Group_3065"
            data-name="Group 3065"
            transform="translate(18.276 13.019)"
          >
            <path
              id="Path_8285"
              data-name="Path 8285"
              d="M185.525,187.945h-2.279A.246.246,0,0,1,183,187.7V185.42a.369.369,0,0,0-.369-.369h-2.9a.369.369,0,0,0-.369.369V187.7a.246.246,0,0,1-.246.246H176.84a.369.369,0,0,0-.369.369v2.9a.369.369,0,0,0,.369.369h2.279a.246.246,0,0,1,.246.246V194.1a.369.369,0,0,0,.369.369h2.9A.369.369,0,0,0,183,194.1v-2.279a.246.246,0,0,1,.246-.246h2.279a.369.369,0,0,0,.369-.369v-2.9A.369.369,0,0,0,185.525,187.945Z"
              transform="translate(-176.471 -185.051)"
              fill="#efecef"
            ></path>
          </g>
          <g
            id="Group_3066"
            data-name="Group 3066"
            transform="translate(23.06 13.019)"
          >
            <path
              id="Path_8286"
              data-name="Path 8286"
              d="M247.934,187.945h-2.279a.246.246,0,0,1-.246-.246V185.42a.369.369,0,0,0-.369-.369h-1.448v9.423h1.448a.369.369,0,0,0,.369-.369v-2.279a.246.246,0,0,1,.246-.246h2.279a.369.369,0,0,0,.369-.369v-2.9A.369.369,0,0,0,247.934,187.945Z"
              transform="translate(-243.592 -185.051)"
              fill="#e2d8ec"
            ></path>
          </g>
          <g
            id="Group_3068"
            data-name="Group 3068"
            transform="translate(23.164 9.227)"
          >
            <g id="Group_3067" data-name="Group 3067" transform="translate(0)">
              <path
                id="Path_8287"
                data-name="Path 8287"
                d="M253.247,130.931l-1.225.357a7.276,7.276,0,0,1-1.05.223v5.451a12.665,12.665,0,0,1-5.292,10.3l-2.088,1.5v2.977a.759.759,0,0,0,.525-.137l4.244-3.039a12.665,12.665,0,0,0,5.292-10.3v-7.023A.317.317,0,0,0,253.247,130.931Z"
                transform="translate(-243.591 -130.918)"
                fill="#da4a54"
              ></path>
            </g>
          </g>
          <g
            id="Group_3069"
            data-name="Group 3069"
            transform="translate(7.861 2.061)"
          >
            <path
              id="Path_8288"
              data-name="Path 8288"
              d="M37.821,33.314a1.95,1.95,0,0,0,.561-1.589.711.711,0,0,1,.263-.624.99.99,0,0,0,.168-.174.977.977,0,0,0-1.557-1.179.668.668,0,0,1-.607.242,1.953,1.953,0,0,0-2.15,2.152.657.657,0,0,1-.2.547.976.976,0,1,0,1.362,1.4l0,0a.693.693,0,0,1,.563-.211A1.948,1.948,0,0,0,37.821,33.314Z"
              transform="translate(-33.999 -29.366)"
              fill="#f4dd45"
            ></path>
          </g>
          <g
            id="Group_3070"
            data-name="Group 3070"
            transform="translate(7.865 2.061)"
          >
            <path
              id="Path_8289"
              data-name="Path 8289"
              d="M38.839,29.732a.975.975,0,0,0-.815-.363.974.974,0,0,1-.189.665.988.988,0,0,1-.168.174.711.711,0,0,0-.263.624,1.953,1.953,0,0,1-2.152,2.15.692.692,0,0,0-.563.211l0,0a.972.972,0,0,1-.637.284.975.975,0,0,0,1.662.61l0,0a.693.693,0,0,1,.563-.211,1.953,1.953,0,0,0,2.152-2.15.711.711,0,0,1,.263-.624.99.99,0,0,0,.168-.174A.98.98,0,0,0,38.839,29.732Z"
              transform="translate(-34.05 -29.367)"
              fill="#eab14d"
            ></path>
          </g>
          <g
            id="Group_3071"
            data-name="Group 3071"
            transform="translate(14.327 0.511)"
          >
            <circle
              id="Ellipse_29"
              data-name="Ellipse 29"
              cx="1.278"
              cy="1.278"
              r="1.278"
              transform="translate(0)"
              fill="#c8ea73"
            ></circle>
          </g>
          <g
            id="Group_3072"
            data-name="Group 3072"
            transform="translate(13.377 32.263)"
          >
            <circle
              id="Ellipse_30"
              data-name="Ellipse 30"
              cx="1.278"
              cy="1.278"
              r="1.278"
              fill="#bed8fb"
            ></circle>
          </g>
          <g
            id="Group_3073"
            data-name="Group 3073"
            transform="translate(14.379 0.562)"
          >
            <path
              id="Path_8290"
              data-name="Path 8290"
              d="M125.23,8.007a1.275,1.275,0,0,1-1.58,1.58,1.277,1.277,0,1,0,1.58-1.58Z"
              transform="translate(-123.65 -8.007)"
              fill="#aad880"
            ></path>
          </g>
          <g
            id="Group_3074"
            data-name="Group 3074"
            transform="translate(13.429 32.314)"
          >
            <path
              id="Path_8291"
              data-name="Path 8291"
              d="M112.049,459.485a1.275,1.275,0,0,1-1.58,1.58,1.277,1.277,0,1,0,1.58-1.58Z"
              transform="translate(-110.469 -459.485)"
              fill="#9dc6fb"
            ></path>
          </g>
          <g
            id="Group_3075"
            data-name="Group 3075"
            transform="translate(37.539 16.317)"
          >
            <circle
              id="Ellipse_31"
              data-name="Ellipse 31"
              cx="1.278"
              cy="1.278"
              r="1.278"
              fill="#bed8fb"
            ></circle>
          </g>
          <g
            id="Group_3076"
            data-name="Group 3076"
            transform="translate(37.59 16.367)"
          >
            <path
              id="Path_8292"
              data-name="Path 8292"
              d="M446.459,232.751a1.275,1.275,0,0,1-1.58,1.58,1.277,1.277,0,1,0,1.58-1.58Z"
              transform="translate(-444.879 -232.751)"
              fill="#9dc6fb"
            ></path>
          </g>
          <g
            id="Group_3077"
            data-name="Group 3077"
            transform="translate(5.888 0)"
          >
            <path
              id="Path_8293"
              data-name="Path 8293"
              d="M337.362,444.7a1.674,1.674,0,1,0,.424.817A1.672,1.672,0,0,0,337.362,444.7Zm-.984,1.759a.65.65,0,0,1-.68-.137.985.985,0,0,0-.676-.274.747.747,0,0,1-.9-.346.656.656,0,0,1,.376-.889.971.971,0,0,0,.608-.658.651.651,0,0,1,1.26.324.993.993,0,0,0,.253.92A.655.655,0,0,1,336.378,446.462Z"
              transform="translate(-309.397 -411.529)"
            ></path>
            <path
              id="Path_8294"
              data-name="Path 8294"
              d="M70.974,32.139l-.582-.953a.315.315,0,0,1-.026-.259,2.4,2.4,0,0,0,.111-.721,2.456,2.456,0,0,0-1.318-2.163.328.328,0,0,1-.171-.2l-.233-.891a.846.846,0,0,0-1.62,0l-.233.891a.328.328,0,0,1-.171.2,2.538,2.538,0,0,0-.625.471,15.28,15.28,0,0,0-.928-1.455,4.272,4.272,0,0,0,1.848-3.494,4.355,4.355,0,0,0-4.407-4.291,4.449,4.449,0,0,0-4.02,2.533,16.124,16.124,0,0,0-6.339-1.292A15.748,15.748,0,0,0,37.333,30.9a.508.508,0,0,0,.327.649.529.529,0,0,0,.667-.318,14.815,14.815,0,0,1,28.535,2.812,1.76,1.76,0,0,0,.075,2.938,13.984,13.984,0,0,1-.476,2.683c-.189.636.839.915,1.013.27a14.987,14.987,0,0,0,.491-2.659,1.789,1.789,0,1,0-.02-3.577l-.072,0q-.076-.525-.19-1.041a2.618,2.618,0,0,0,.857-.055.309.309,0,0,1,.223.037l1.064.616a.829.829,0,0,0,1.146-1.116Zm-2.242,3.346a.787.787,0,1,1-.787-.766A.777.777,0,0,1,68.732,35.484Zm-5.9-11.013a15.786,15.786,0,0,0-3.283-2.224A3.388,3.388,0,0,1,62.618,20.3a3.317,3.317,0,0,1,3.357,3.269,3.254,3.254,0,0,1-1.43,2.675A15.633,15.633,0,0,0,62.828,24.472ZM69.3,31.759a1.374,1.374,0,0,0-1.007-.152,2.073,2.073,0,0,1-.888-.029,15.035,15.035,0,0,0-.773-2.039,1.478,1.478,0,0,1,.6-.6,1.377,1.377,0,0,0,.71-.943,1.377,1.377,0,0,0,.71.943,1.438,1.438,0,0,1,.772,1.266,1.4,1.4,0,0,1-.065.422,1.465,1.465,0,0,0,.278,1.329Z"
              transform="translate(-35.099 -17.92)"
            ></path>
            <path
              id="Path_8295"
              data-name="Path 8295"
              d="M37.323,219.92a.532.532,0,0,0-.694.26,14.789,14.789,0,0,1-13.491,8.536A14.562,14.562,0,0,1,8.39,214.382a14.077,14.077,0,0,1,.227-2.521.513.513,0,0,0-.426-.593.525.525,0,0,0-.61.414,15.052,15.052,0,0,0,1.466,9.663,2.533,2.533,0,0,0-.867-.094A2.441,2.441,0,0,0,5.9,223.416a2.36,2.36,0,0,0,1.179,2.267.238.238,0,0,1,.126.175,2.35,2.35,0,0,0,.149.672,2.467,2.467,0,0,0,2.719,1.523,2.414,2.414,0,0,0,2.039-2.193.24.24,0,0,1,.127-.177c.041-.024.08-.049.12-.075A16.045,16.045,0,0,0,37.591,220.6a.506.506,0,0,0-.268-.675ZM11.06,225.79a1.383,1.383,0,0,1-1.169,1.255,1.424,1.424,0,0,1-1.552-.87,1.352,1.352,0,0,1-.086-.387,1.252,1.252,0,0,0-.632-.981,1.352,1.352,0,0,1-.675-1.3,1.394,1.394,0,0,1,1.3-1.235,1.44,1.44,0,0,1,.768.163,1.366,1.366,0,0,0,.757.151,15.512,15.512,0,0,0,1.822,2.283A1.242,1.242,0,0,0,11.06,225.79Z"
              transform="translate(-5.888 -196.37)"
            ></path>
            <path
              id="Path_8296"
              data-name="Path 8296"
              d="M100.822,113.9l4.244,3.039a1.271,1.271,0,0,0,1.48,0l4.244-3.039a13.2,13.2,0,0,0,5.505-10.713V96.159a.828.828,0,0,0-1.06-.795l-1.225.357a6.789,6.789,0,0,1-6.055-1.159l-1.446-1.126a1.143,1.143,0,0,0-1.407,0l-1.446,1.126A6.79,6.79,0,0,1,97.6,95.721l-1.225-.357a.828.828,0,0,0-1.06.795v7.023A13.2,13.2,0,0,0,100.822,113.9Zm6.965-10.847h2.137v2.611h-2.137a.758.758,0,0,0-.758.758v2.137h-2.611v-2.137a.758.758,0,0,0-.758-.758h-2.137v-2.611h2.137a.758.758,0,0,0,.758-.758v-2.137h2.611v2.137A.758.758,0,0,0,107.788,103.048ZM96.34,96.419l.976.284a7.814,7.814,0,0,0,6.97-1.334l.928-.723v4.483h-.936a.882.882,0,0,0-.881.881v2.014h-2.014a.882.882,0,0,0-.881.881v2.9a.882.882,0,0,0,.881.88H103.4V108.7a.882.882,0,0,0,.881.881h.936v1.116a.511.511,0,1,0,1.023,0v-1.116h.936a.882.882,0,0,0,.881-.881v-2.014h2.014a.881.881,0,0,0,.881-.88v-2.9a.882.882,0,0,0-.881-.881h-2.014v-2.014a.882.882,0,0,0-.881-.881h-.936V94.519l1.092.85A7.814,7.814,0,0,0,114.3,96.7l.976-.284v6.763a12.179,12.179,0,0,1-5.078,9.882l-3.878,2.777v-2.883a.511.511,0,1,0-1.023,0v2.883l-3.878-2.777a12.18,12.18,0,0,1-5.078-9.882V96.419Z"
              transform="translate(-88.645 -86.619)"
            ></path>
            <path
              id="Path_8297"
              data-name="Path 8297"
              d="M28.164,28.17a1.5,1.5,0,0,0,1.119-.438.186.186,0,0,1,.145-.063,2.465,2.465,0,0,0,2.715-2.713c0-.194.246-.275.33-.437a1.488,1.488,0,0,0-2.244-1.943c-.093.078-.124.212-.276.191a2.465,2.465,0,0,0-2.713,2.715c-.01.177-.216.259-.282.422a1.488,1.488,0,0,0,1.2,2.266Zm-.333-1.73a1.291,1.291,0,0,0,.428-1.067,1.442,1.442,0,0,1,1.587-1.589,1.171,1.171,0,0,0,1.067-.439c.411-.537,1.188.218.662.644a1.215,1.215,0,0,0-.449,1.075,1.442,1.442,0,0,1-1.589,1.588,1.2,1.2,0,0,0-.982.361A.466.466,0,0,1,27.832,26.44Z"
              transform="translate(-25.279 -20.59)"
            ></path>
            <path
              id="Path_8298"
              data-name="Path 8298"
              d="M117.432,3.578a1.789,1.789,0,1,0-1.789-1.789A1.791,1.791,0,0,0,117.432,3.578Zm0-2.555a.766.766,0,1,1-.766.766A.767.767,0,0,1,117.432,1.023Z"
              transform="translate(-107.722 0)"
            ></path>
            <path
              id="Path_8299"
              data-name="Path 8299"
              d="M104.251,451.477a1.789,1.789,0,1,0,1.789,1.789A1.791,1.791,0,0,0,104.251,451.477Zm0,2.555a.766.766,0,1,1,.766-.766A.767.767,0,0,1,104.251,454.032Z"
              transform="translate(-95.492 -419.725)"
            ></path>
            <circle
              id="Ellipse_32"
              data-name="Ellipse 32"
              cx="0.622"
              cy="0.622"
              r="0.622"
              transform="translate(4.976 32.296)"
            ></circle>
            <circle
              id="Ellipse_33"
              data-name="Ellipse 33"
              cx="0.622"
              cy="0.622"
              r="0.622"
              transform="translate(1.355 8.435)"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ImmuneIcon;
