import React from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HealthArticlesTopics = () => {
  const list = [1, 2, 3, 4, 5, 6, 5, 4, 5];
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <DetailNavbar title={t("Explore")} />

      <Wrapper>
        {list.map((item, index) => (
          <SingleItem key={index} img={Images.Article} name="Mind" />
        ))}
      </Wrapper>
    </Container>
  );
};

const SingleItem = ({ img, name }) => (
  <Link to={`/health-articles/${name.toLowerCase()}`}>
    <SingleItemWrapper>
      <img src={img} alt="images" className="w-full h-full object-cover" />

      <Overlay>
        <Text>{name}</Text>
      </Overlay>
    </SingleItemWrapper>
  </Link>
);

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;

const Wrapper = tw.div`grid grid-cols-2 gap-5 mt-5 px-4`;

const SingleItemWrapper = tw.div`
  w-full relative bg-white shadow-md overflow-hidden rounded-lg flex space-x-2 `;

const Overlay = tw.div`absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center`;
const Text = tw.p` bg-alpha-dark-black shadow-lg tracking-wider uppercase font-medium  text-white p-2.5 rounded-lg`;

export default HealthArticlesTopics;
