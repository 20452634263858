import React from "react";
import ArrowIcon from "../SVGs/ArrowIcon";
import tw from "tailwind-styled-components";
import VideoIcon from "../SVGs/VideoIcon";
import { Link } from "react-router-dom";

const SingleCallComp = ({ color, title, subTitle, link, url }) => {
  return (
    <Wrapper>
      <Link to={url}>
        <VideoIconWrapper $color={color}>
          <VideoIcon />
        </VideoIconWrapper>

        <Title>{title}</Title>
        {/* <SubTitle>{subTitle}</SubTitle> */}

        <LinkWrapper $color={color}>
          {link}
          <Circle $color={color}>
            <ArrowIcon />
          </Circle>
        </LinkWrapper>
      </Link>
    </Wrapper>
  );
};

const Wrapper = tw.div`
bg-white w-full rounded-lg shadow-lg p-4 pt-10 relative`;

const VideoIconWrapper = tw.div`
${(p) => (p.$color === "orange" ? "bg-custom-orange" : "bg-custom-blue")}
absolute -top-6 left-6 p-1.5 rounded`;

const Title = tw.h2`font-bold text-sm text-gray-900 `;
const SubTitle = tw.h4`text-gray-500 text-xs`;

const LinkWrapper = tw.h4`
${(p) => (p.$color === "orange" ? "text-custom-orange" : "text-custom-blue")}
w-full items-center justify-between text-xs mt-4 flex items-center justify-between`;

const Circle = tw.h4`
${(p) => (p.$color === "orange" ? "bg-custom-orange" : "bg-custom-blue")}
p-0.5 rounded-full `;

export default SingleCallComp;
