import React from "react";
import Model from "./Model";
import tw from "tailwind-styled-components";
import { useDispatch } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { useTranslation } from "react-i18next";

const AccountModel = ({ setOpenModel, data }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  return (
    <Model width={"w-11/12 max-w-sm"} setOpenModel={setOpenModel}>
      <div className="px-4 py-2">
        <Title>{t("Payment_Title")}</Title>
        <SubTitle>
        {t("Payment_Subtitle")}
        </SubTitle>

        {/* <Label>{t("Bank_Name")}</Label>
        <Value>{data.bankName}</Value>
        <Label>{t("Account_Name")}</Label>
        <Value>{data.accountName}</Value>
        <Label>{t("Account_No")}</Label> */}
        {/* <Value>{data.accountNo}</Value> */}
        <Label>{t("More_Info")}</Label>
        <Value>{data.bankNumber}</Value>

        <BtnWrapper>
          <Confirm onClick={() => setOpenModel(false)}>Okay</Confirm>
        </BtnWrapper>
      </div>
    </Model>
  );
};

const Title = tw.h2`text-gray-800 font-medium  mb-2 text-xl`;
const SubTitle = tw.h2`text-gray-500  mb-8 text-sm`;
const Value = tw.h2`text-gray-700 font-medium  mb-8`;
const Label = tw.h2`text-gray-500 text-sm `;
const BtnWrapper = tw.div` flex items-center space-x-3 justify-end mt-5`;
const Confirm = tw.button`text-white  text-sm bg-custom-green w-20 h-10 rounded-md flex justify-center items-center`;
const Cancel = tw.button`text-gray-700  text-sm bg-gray-100 w-20 h-10 rounded-md flex justify-center items-center`;

export default AccountModel;
