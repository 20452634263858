import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import Searchbar from "../Components/Searchbar";
import CallIcon from "../Components/SVGs/CallIcon";
import LocationIcon from "../Components/SVGs/LocationIcon";
import VideoFilledIcon from "../Components/SVGs/VideoFilledIcon";
import Doctor from "../Components/SVGs/Doctor";
import MedicalStore from "../Components/SVGs/MedicalStore";
import ReactWhatsapp from "react-whatsapp";
import Config from "../Config";
import axios from "axios";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MedicineList = () => {
  const { pharmacyId } = useParams();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [list, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.UserReducer.user);

  const WhatsAppHandler = (mobile) => {
    window.open(`https://wa.me/${mobile}`, "_blank");
  };

  const searchMedicineListHandler = async (query) => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("id", pharmacyId);
      body.append("query", query);
      body.append("lang", i18n.language);

      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.SearchMedicineList, body, axiosConfig);
      setLoading(false);
      if (res.data.status === "OK")
      {
        //console.log(res.data.list)
        setData(res.data.list);
      }
      else {
        dispatch(Remove_User())
        window.location.replace(`/`);
      }
    }
  };

  const fetchMedicinessList = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("id", pharmacyId);
      body.append("lang", i18n.language);
      
      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.GetMedicineList, body, axiosConfig);
      setLoading(false);
      if (res.data.status === "OK")
      {
        //console.log(res.data.list)
        setData(res.data.list);
      }
      else {
        dispatch(Remove_User())
        window.location.replace(`/`);
      }
    }
  };

  useEffect(() => {
    fetchMedicinessList();
  }, []);

  return (
    <Container>
      <DetailNavbar title={t("MedicineList")}  route="/medical-stores" />
      <Searchbar searchMedicineListHandler={searchMedicineListHandler} />
      <Wrapper>
        {/* loader */}
        {loading && (
          <div
            className={`w-full ${loading && "h-96"} grid place-items-center`}
          >
            <img
              src={Images.GreenLoader}
              alt="loading..."
              className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
            />
          </div>
        )}

        {!loading && list == false && (
           <div
            className={`w-full ${!loading && "h-96"} grid place-items-center`}
          >
            No Record
          </div>
        )}

        {!loading && list.length > 0 &&
          list.map((item, index) => (
            <SingleItem
              key={index}
              medicineName={item.medicineName}
              code={item.productCode}
              price={item.price}
              handler={WhatsAppHandler}
              mobile={item.msisdn}
            />
          ))}
      </Wrapper>
    </Container>
  );
};

const SingleItem = ({ medicineName, code, price, handler, mobile }) => (
  <SingleItemWrapper>
    <img src="./images/medication.svg" alt="create icon" />
    <TextWrapper>
      <Title>{medicineName}</Title>
      {/* <ProductCode>{code}</ProductCode> */}
      {/* <Location>
        <LocationIcon />
        <p>{location}</p>
      </Location> */}
    </TextWrapper>
    {/* <Icons>
      <WhatsAppBtn title="Create Report" onClick={() => handler(mobile)}>
        <img src={Images.WhatsApp} alt="create icon" />
      </WhatsAppBtn>
    </Icons> */}
  </SingleItemWrapper>
);

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;

const Wrapper = tw.div`flex flex-col space-y-5 mt-5 px-4`;

const SingleItemWrapper = tw.div`
w-full bg-white shadow-md p-3 rounded-lg flex space-x-2 `;
const WhatsAppBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const TextWrapper = tw.div`w-full`;
const Title = tw.h3`font-medium text-gray-900 mt-1`;
const ProductCode = tw.div`text-sm text-gray-500`;
const Location = tw.div`flex space-x-1 items-center text-sm text-gray-500`;
const Icons = tw.div`flex flex-col space-y-2`;

export default MedicineList;
