import React from "react";

const YogaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50"
      height="50"
    >
      <g id="yoga_1" transform="translate(-278 -326)">
        <rect
          id="Rectangle_1567"
          data-name="Rectangle 1567"
          width="50"
          height="50"
          transform="translate(278 326)"
          fill="#fff"
        ></rect>
        <g id="yoga" transform="translate(268.309 333)">
          <path
            id="Path_8115"
            data-name="Path 8115"
            d="M79.41,446.138H54.741a2.2,2.2,0,0,1-2.214-2.179h0a2.2,2.2,0,0,1,2.214-2.179H79.41a2.2,2.2,0,0,1,2.214,2.179h0A2.2,2.2,0,0,1,79.41,446.138Z"
            transform="translate(-32.384 -410.722)"
            fill="#96e6ff"
          ></path>
          <path
            id="Path_8116"
            data-name="Path 8116"
            d="M64.979,441.78H54.706a2.179,2.179,0,0,0,0,4.358H64.979V441.78Z"
            transform="translate(-32.384 -410.722)"
            fill="#00d2ff"
          ></path>
          <path
            id="Path_8117"
            data-name="Path 8117"
            d="M186.448,222.066l1.093-9.891a4.248,4.248,0,0,0-1.821-.41h0a.623.623,0,0,0-.623.623v1.245a3.113,3.113,0,1,1-6.226,0v-1.245a.623.623,0,0,0-.623-.623h0a4.248,4.248,0,0,0-1.821.41l1.093,9.891a4.981,4.981,0,0,1-.115,1.79l-1.024,4.1h11.207l-1.024-4.1A4.984,4.984,0,0,1,186.448,222.066Z"
            transform="translate(-147.292 -196.895)"
            fill="#fff082"
          ></path>
          <g
            id="Group_2931"
            data-name="Group 2931"
            transform="translate(29.034 19.862)"
          >
            <path
              id="Path_8118"
              data-name="Path 8118"
              d="M180.7,289.754a1.261,1.261,0,0,0-.978-1.214,4.006,4.006,0,0,1-2.771-3.476l.568,5.143A4.979,4.979,0,0,1,177.4,292l-1.024,4.1H180.7Z"
              transform="translate(-176.38 -284.897)"
              fill="#ffdc64"
            ></path>
            <path
              id="Path_8119"
              data-name="Path 8119"
              d="M184.219,282.689h0Z"
              transform="translate(-183.667 -282.689)"
              fill="#ffdc64"
            ></path>
          </g>
          <g
            id="Group_2932"
            data-name="Group 2932"
            transform="translate(18.275 15.28)"
          >
            <path
              id="Path_8120"
              data-name="Path 8120"
              d="M337.175,229.631h-.056a1.245,1.245,0,0,0-1.245,1.245l-4.358-4.358-.752-5.638a4.263,4.263,0,0,0-2.407-3.291l-.5,4.532h0l.947,4.533a3.736,3.736,0,0,0,1.022,1.909l3.892,3.892a3.113,3.113,0,0,0,2.2.912h1.2a1.869,1.869,0,0,0,1.867-1.877v-.047A1.811,1.811,0,0,0,337.175,229.631Z"
              transform="translate(-306.155 -217.589)"
              fill="#f5c882"
            ></path>
            <path
              id="Path_8121"
              data-name="Path 8121"
              d="M37.122,222.172l-.506-4.581a4.263,4.263,0,0,0-2.407,3.291l-.752,5.638L29.1,230.878v-.106a1.14,1.14,0,0,0-1.14-1.14h0a1.939,1.939,0,0,0-1.971,1.767,1.868,1.868,0,0,0,1.865,1.968h1.2a3.113,3.113,0,0,0,2.2-.912l3.892-3.892a3.735,3.735,0,0,0,1.022-1.909l.953-4.484Z"
              transform="translate(-25.987 -217.591)"
              fill="#f5c882"
            ></path>
          </g>
          <circle
            id="Ellipse_19"
            data-name="Ellipse 19"
            cx="1.868"
            cy="1.868"
            r="1.868"
            transform="translate(32.823 0.584)"
            fill="#5b5d6e"
          ></circle>
          <path
            id="Path_8122"
            data-name="Path 8122"
            d="M206.656,87.161h0a3.736,3.736,0,0,1-3.736-3.736V79.067h7.471v4.358A3.736,3.736,0,0,1,206.656,87.161Z"
            transform="translate(-171.965 -73.509)"
            fill="#f5c882"
          ></path>
          <path
            id="Path_8123"
            data-name="Path 8123"
            d="M202.92,79.067v4.209a3.853,3.853,0,0,0,2.932,3.8,3.679,3.679,0,0,0,2.049-.128,3.736,3.736,0,0,1-2.476-3.191.433.433,0,0,1,.293-.435,14.164,14.164,0,0,0,2.516-1.138,5.554,5.554,0,0,0,2.158,1.346V79.067Z"
            transform="translate(-171.965 -73.509)"
            fill="#f5af73"
          ></path>
          <path
            id="Path_8124"
            data-name="Path 8124"
            d="M155.443,444.892l-4.628-1.029a1.245,1.245,0,0,1-.975-1.215h0v-.869h5.6v3.113Z"
            transform="translate(-122.697 -410.721)"
            fill="#f5c882"
          ></path>
          <path
            id="Path_8125"
            data-name="Path 8125"
            d="M192.076,47.416a15,15,0,0,1-6.849,1.817v-.572a4.981,4.981,0,0,1,4.981-4.981h0a4.981,4.981,0,0,1,4.981,4.981v.623A4.293,4.293,0,0,1,192.076,47.416Z"
            transform="translate(-155.517 -40.606)"
            fill="#5b5d6e"
          ></path>
          <path
            id="Path_8126"
            data-name="Path 8126"
            d="M191.893,43.979a4.969,4.969,0,0,0-6.666,4.682v.572a15.486,15.486,0,0,0,3.12-.391A5.287,5.287,0,0,1,191.893,43.979Z"
            transform="translate(-155.545 -40.606)"
            fill="#464655"
          ></path>
          <path
            id="Path_8127"
            data-name="Path 8127"
            d="M202.92,441.78v2.7l1.868.415V441.78Z"
            transform="translate(-172.005 -410.722)"
            fill="#f5af73"
          ></path>
          <path
            id="Path_8128"
            data-name="Path 8128"
            d="M50.979,216.626a2.429,2.429,0,0,0-1.717-.7h-.057a1.86,1.86,0,0,0-1.5.763l-3.614-3.566-.735-5.442a4.909,4.909,0,0,0-4.873-4.208,1.216,1.216,0,0,0-1.223,1.206v1.245a2.564,2.564,0,0,1-5.128,0v-1.245a1.216,1.216,0,0,0-1.224-1.206,4.908,4.908,0,0,0-4.871,4.208l-.736,5.442-3.613,3.565a1.861,1.861,0,0,0-1.507-.762h0a2.451,2.451,0,0,0-.073,4.9,2.721,2.721,0,0,0-.518,1.6,2.785,2.785,0,0,0,2.8,2.763H47a2.785,2.785,0,0,0,2.8-2.763,2.722,2.722,0,0,0-.518-1.6,2.474,2.474,0,0,0,2.411-2.459v-.047A2.363,2.363,0,0,0,50.979,216.626ZM40.2,213.646a4.288,4.288,0,0,0,1.2,2.207l3.859,3.807h-4.42l-.926-3.654a4.323,4.323,0,0,1-.1-1.58l.2-1.711ZM30.9,204.64a.039.039,0,0,1,.039.039v1.245a3.747,3.747,0,0,0,7.494,0v-1.245a.04.04,0,0,1,.041-.039,3.743,3.743,0,0,1,1.274.224l-1.124,9.426a5.468,5.468,0,0,0,.13,2l.854,3.371H29.769l.854-3.371a5.473,5.473,0,0,0,.13-2l-1.124-9.426A3.747,3.747,0,0,1,30.9,204.64Zm1.3,17.989-3.971-.871a.66.66,0,0,1-.525-.646v-.285h4.5Zm-2.832-9.914.2,1.711a4.324,4.324,0,0,1-.1,1.58l-.926,3.654H24.129l3.859-3.807a4.288,4.288,0,0,0,1.2-2.207Zm-10.5,5.661a1.3,1.3,0,0,1,1.3-1.284.671.671,0,0,1,.474.194.653.653,0,0,1,.2.468.584.584,0,0,0,.365.539.6.6,0,0,0,.645-.127l4.418-4.358a.581.581,0,0,0,.168-.336l.762-5.638a3.662,3.662,0,0,1,1.309-2.335l.413,3.467-.9,4.45a3.129,3.129,0,0,1-.874,1.611l-4.7,4.633H20.176A1.294,1.294,0,0,1,18.874,218.377Zm1.893,4.047a1.608,1.608,0,0,1,1.617-1.6h4.141v.285a1.824,1.824,0,0,0,1.452,1.785l4.228.927v.193H22.385A1.608,1.608,0,0,1,20.768,222.423Zm26.23,1.6H33.389v-3.191H47a1.6,1.6,0,1,1,0,3.191Zm3.51-5.652a1.3,1.3,0,0,1-1.3,1.293h-2.28l-4.7-4.633a3.129,3.129,0,0,1-.874-1.611l-.9-4.45.413-3.467a3.662,3.662,0,0,1,1.309,2.335l.762,5.638a.581.581,0,0,0,.168.336l4.418,4.358a.6.6,0,0,0,.645.127.584.584,0,0,0,.365-.539.667.667,0,0,1,.67-.662h.057a1.246,1.246,0,0,1,.88.36,1.212,1.212,0,0,1,.365.869v.047Z"
            transform="translate(0 -189.186)"
          ></path>
          <path
            id="Path_8129"
            data-name="Path 8129"
            d="M177.512,9.211c.038,0,.278,0,.665-.025v.737a4.319,4.319,0,1,0,8.639,0V9.2a3.429,3.429,0,0,0,.662.065.584.584,0,0,0,.584-.584V8.055a5.571,5.571,0,0,0-3.181-5.027,2.452,2.452,0,1,0-4.766,0,5.571,5.571,0,0,0-3.182,5.027v.572A.584.584,0,0,0,177.512,9.211Zm8.136.712a3.152,3.152,0,0,1-6.3,0V9.067a14.891,14.891,0,0,0,4.869-1.5,5.579,5.579,0,0,0,1.435,1.194Zm-4.436-7.471a1.284,1.284,0,1,1,2.553.187,5.525,5.525,0,0,0-2.538,0A1.306,1.306,0,0,1,181.213,2.452ZM182.5,3.658a4.4,4.4,0,0,1,4.4,4.359,4.151,4.151,0,0,1-2.044-1.532.584.584,0,0,0-.782-.177A14.2,14.2,0,0,1,178.1,8.019,4.4,4.4,0,0,1,182.5,3.658Z"
            transform="translate(-147.806)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default YogaIcon;
