import React from "react";

const StepIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      width="30"
      height="30"
    >
      <g id="steps_tips" transform="translate(-68 -1024)">
        <circle
          id="Ellipse_39"
          data-name="Ellipse 39"
          cx="18"
          cy="18"
          r="18"
          transform="translate(68 1024)"
          fill="#dcc063"
        ></circle>
        <g id="walk" transform="translate(75 1000.997)">
          <path
            id="Path_8488"
            data-name="Path 8488"
            d="M3.778,451H.667a.667.667,0,0,0,0,1.333H3.778a.667.667,0,0,0,0-1.333Z"
            transform="translate(0 -401.33)"
            fill="#fff"
          ></path>
          <path
            id="Path_8489"
            data-name="Path 8489"
            d="M160.978,451H150.667a.667.667,0,0,0,0,1.333h10.311a.667.667,0,0,0,0-1.333Z"
            transform="translate(-143.787 -401.33)"
            fill="#fff"
          ></path>
          <path
            id="Path_8490"
            data-name="Path 8490"
            d="M412,231.982v4.4h4.4a5.122,5.122,0,0,0-4.4-4.4Z"
            transform="translate(-394.444 -192.046)"
            fill="#fff"
          ></path>
          <path
            id="Path_8491"
            data-name="Path 8491"
            d="M11.882,218.135a.628.628,0,0,1-.322-.089L.583,211.49l-.322.577a2.047,2.047,0,0,0-.193,1.518,1.98,1.98,0,0,0,.9,1.214l9.3,5.556a3.145,3.145,0,0,0,1.611.447h8.184a1.97,1.97,0,0,0,1.934-2v-.667Z"
            transform="translate(0 -172.465)"
            fill="#fff"
          ></path>
          <path
            id="Path_8492"
            data-name="Path 8492"
            d="M31.435,125.508a3.345,3.345,0,0,0-1.192-4.535,6,6,0,0,0-.343.528l-1.351,2.34Z"
            transform="translate(-27.279 -85.971)"
            fill="#fff"
          ></path>
          <path
            id="Path_8493"
            data-name="Path 8493"
            d="M89.161,36.685a4.635,4.635,0,0,1-.471,3.519l7.158,4.133h4.509V39.892H99.009a1.108,1.108,0,0,1-.747-.288l-1.306.754A.667.667,0,0,1,96.29,39.2l1.363-.787-.517-1.241-1.513.874a.667.667,0,1,1-.667-1.155l1.662-.96-.4-.965a.666.666,0,0,0-.689-.406,3.362,3.362,0,0,1-3.706-2.95.688.688,0,0,0-.3-.5.667.667,0,0,0-.783.045L87.4,33.886c-.047.038-.093.078-.139.118A4.636,4.636,0,0,1,89.161,36.685Z"
            transform="translate(-83.682)"
            fill="#fff"
          ></path>
          <path
            id="Path_8494"
            data-name="Path 8494"
            d="M5.556,391H.667a.667.667,0,1,0,0,1.333H5.556a.667.667,0,1,0,0-1.333Z"
            transform="translate(0 -343.997)"
            fill="#fff"
          ></path>
          <path
            id="Path_8495"
            data-name="Path 8495"
            d="M444.444,451h-1.778a.667.667,0,0,0,0,1.333h1.778a.667.667,0,0,0,0-1.333Z"
            transform="translate(-423.111 -401.33)"
            fill="#fff"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default StepIcon;
