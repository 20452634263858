import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import CovidIcon from "../Components/SVGs/CovidIcon";
import DiabetesIcon from "../Components/SVGs/DiabetesIcon";
import WellnessIcon from "../Components/SVGs/WellnessIcon";
import MindIcon from "../Components/SVGs/MindIcon";
import ImmuneIcon from "../Components/SVGs/ImmuneIcon";
import YogaIcon from "../Components/SVGs/YogaIcon";
import Bones from "../Components/SVGs/Bones";
import ReactPlayer from "react-player";
import Config from "../Config";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Categories = () => {
  const { category } = useParams();
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(i18n.language || "english");

  const list = [
    { param: "Covid", name: t("Covid"), icon: <CovidIcon /> },
    { param: "Diabetes", name: t("Diabetes"), icon: <DiabetesIcon /> },
    { param: "Wellness", name: t("Wellness"), icon: <WellnessIcon /> },
    { param: "Mind", name: t("Mind"), icon: <MindIcon /> },
    { param: "Immune", name: t("Immune"), icon: <ImmuneIcon /> },
    { param: "Yoga", name: t("Yoga"), icon: <YogaIcon /> },
    { param: "Bones", name: t("Bones"), icon: <Bones /> },
  ];

  const [videoList, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchVideoList = async () => {
    setLoading(true);

    const body = new FormData();

    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(
      Config.GetVideoList + category +  "/" + activeLang + "/data.json",
      body,
      axiosConfig
    );
    if (res.data.status === "OK") setData(res.data.list);

    setLoading(false);
  };

  useEffect(() => {
    fetchVideoList();
  }, [category]);

  return (
    <Container>
      {category == "bestvideos" ? (
        <DetailNavbar title={t("Videos")} />
      ) : (
        <DetailNavbar
          title={t(category.charAt(0).toUpperCase() + category.slice(1))}
        />
      )}
      <CategoriesWrapper>
        <Swiper
          slidesPerView={6}
          spaceBetween={20}
          scrollbar={true}
          navigation={true}
          modules={[Keyboard, Scrollbar, Navigation, Pagination]}
          className="VideoSwiper mt-3"
        >
          {list.map((item) => (
            <SwiperSlide key={item.name}>
              <SingleCategory
                icon={item.icon}
                name={item.name}
                param={item.param}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </CategoriesWrapper>

      <VideoWrapper>
        {videoList.map((videoObj, index) => (
          <SingleVideo
            t={t}
            video={videoObj.video}
            poster={videoObj.poster}
            title={videoObj.title}
            views={videoObj.views}
          />
        ))}
      </VideoWrapper>
    </Container>
  );
};

const SingleCategory = ({ icon, name, param }) => (
  <Link to={`/categories/${param.toLowerCase()}`}>
    <SingleCategoryWrapper>
      <Figure>{icon}</Figure>
      <CategoryName>{name}</CategoryName>
    </SingleCategoryWrapper>
  </Link>
);

const SingleVideo = ({ video, poster, title, views, t }) => (
  <SingleVideoWrapper>
    <ReactPlayer
      url={video}
      controls={true}
      width="100%"
      playing={true}
      poster={poster}
      height={200}
      light={poster}
      style={{ borderRadius: 11, overflow: "hidden" }}
    />
    {/* <Player src={video} poster={poster}></Player> */}
    <VideoTitle>{title}</VideoTitle>
    <VideoCategory>
      {views} {t("Views")}
    </VideoCategory>
  </SingleVideoWrapper>
);

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden pb-8 `;

const CategoriesWrapper = tw.div``;
const SingleCategoryWrapper = tw.div`flex flex-col items-center justify-center cursor-pointer`;
const Figure = tw.figure`shadow rounded-md overflow-hidden`;
const CategoryName = tw.p`text-sm text-gray-600 mt-1.5`;

const VideoWrapper = tw.div`flex flex-col space-y-6 mt-6 px-4`;
const SingleVideoWrapper = tw.div`w-full`;
const VideoTitle = tw.p`text-gray-800 font-medium text-md mt-2`;
const VideoCategory = tw.p`text-gray-500 text-xs `;

export default Categories;
