import React from "react";
import ReactPlayer from "react-player";
import tw from "tailwind-styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";

import Mindspace from "../SVGs/Mindspace";
import StepIcon from "../SVGs/StepIcon";
import Medication from "../SVGs/Medication";
import { useTranslation } from "react-i18next";

const HealthTips = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <Title>{t("HealthTips")}</Title>
      <Swiper
        slidesPerView={2}
        spaceBetween={20}
        scrollbar={true}
        navigation={true}
        modules={[Keyboard, Scrollbar, Navigation, Pagination]}
        className="VideoSwiper mt-3"
      >
        <SwiperSlide>
          <SingleTip
            icon={<Mindspace />}
            tip={t("MindSpace")}
            desc={t("YourPeace")}
          />
        </SwiperSlide>

        <SwiperSlide>
          <SingleTip
            icon={<StepIcon />}
            tip={t("Step")}
            desc={t("BestMedicine")}
          />
        </SwiperSlide>

        <SwiperSlide>
          <SingleTip
            icon={<Medication />}
            tip={t("Medication")}
            desc={t("MedicationMedicine")}
          />
        </SwiperSlide>
      </Swiper>
    </Wrapper>
  );
};

const SingleTip = ({ icon, tip, desc }) => (
  <SingleTipWrapper>
    <Figure>{icon}</Figure>
    <TextWrapper>
      <Tip>{tip}</Tip>
      <TipDesc>{desc}</TipDesc>
    </TextWrapper>
  </SingleTipWrapper>
);

const Wrapper = tw.div`w-full  `;
const SingleTipWrapper = tw.div`w-full flex space-x-2 bg-white p-3 rounded-lg`;
const Figure = tw.figure`mt-1`;
const TextWrapper = tw.div``;

const Title = tw.h3`text-gray-900 font-bold  text-lg  `;
const Tip = tw.p`text-gray-800 font-medium text-sm mt-1`;
const TipDesc = tw.p`text-gray-500 text-xs `;

export default HealthTips;
