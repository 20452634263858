const Images = {
  Banner1: "./images/1.png",
  Banner2: "./images/2.jpeg",
  Banner3: "./images/3.jpeg",
  Banner4: "./images/4.jpeg",
  Banner5: "./images/5.jpeg",
  Banner6: "./images/7.jpg",
  Article: "./images/article.jpg",
  Article2: "./images/article_2.jpg",
  Article3: "./images/article_3.jpg",
  Article5: "./images/article_5.jpeg",
  Meidicine: "./images/medication.svg",
  WhatsApp: "./images/call_green.svg",
  GreenLoader: "./images/green-loader.gif",
  CrossBlack: "./images/cross-black.svg",
  Search: "./images/search1.svg",
  Hospital: "./images/hospital.png",
  Department: "./images/department.png",
};

export default Images;
