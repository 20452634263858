import React from "react";

const MindIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50"
      height="50"
    >
      <g id="mind_1" transform="translate(-48 -326)">
        <rect
          id="Rectangle_1569"
          data-name="Rectangle 1569"
          width="50"
          height="50"
          transform="translate(48 326)"
          fill="#fff"
        ></rect>
        <g id="mental-health" transform="translate(33.388 333)">
          <g
            id="Group_2925"
            data-name="Group 2925"
            transform="translate(23.138 0.527)"
          >
            <path
              id="Path_8091"
              data-name="Path 8091"
              d="M62.66,19.94c-.934-5.788-5.754-8.6-11.193-9.7,0,0,.136.315-.01-.023-.392-.08-.9-.217-1.418-.288a21.684,21.684,0,0,0-8.135.043q-.641.166-1.279.389h.006a13.756,13.756,0,0,0-1.991.877,9.781,9.781,0,0,0-5.4,8.16,7.834,7.834,0,0,1-.792,2.446c-.158.653.563,1.324.851,1.97a.921.921,0,0,1-.049.839,7.736,7.736,0,0,1-2.228,2.371c-.519.385-.915.729-.913,1.328,0,.7.456,1.156,1.43,1.445,1.634.484.11,1.625-.3,1.9a.417.417,0,0,0-.172.243.6.6,0,0,0,.276.646,6.338,6.338,0,0,1,.775.692.137.137,0,0,1-.017.2,1,1,0,0,0-.438.839.559.559,0,0,0,.395.494,2.466,2.466,0,0,1,.59.275.507.507,0,0,1,.172.641,2.081,2.081,0,0,0-.343,1.778,2.915,2.915,0,0,0,2.686,2.026,21.576,21.576,0,0,0,4.262-.372c3.063-.4,4.471,2.037,5.373,4.135h0a1.272,1.272,0,0,0,.219.431,2.142,2.142,0,0,0,1.969.341l10.083-2.585a1.41,1.41,0,0,0,1.01-1.868c-.875-2.271-1.815-5.444-.663-6.726.055-.059.011-.013.128-.127,3.41-2.974,4.711-4.959,5.087-8.012,0,0-.409,0,0,0a18.883,18.883,0,0,0,.025-4.812Z"
              transform="translate(-30.109 -9.419)"
              fill="#fed2a4"
            ></path>
            <path
              id="Path_8092"
              data-name="Path 8092"
              d="M201.206,29.349a13.686,13.686,0,0,0,2.092-2.717,12.824,12.824,0,0,0,1.3-6.376,12.809,12.809,0,0,0-.484-3.728A10.817,10.817,0,0,0,200.19,11h0a17.278,17.278,0,0,0-6.9-3h0c-.377-.08-.961-.187-1.46-.257a18.653,18.653,0,0,0-8.169.494,19.94,19.94,0,0,1,6.485-.072c.5.07,1.084.177,1.46.257h0c5.232,1.1,10.252,4.2,11.151,9.993a15.6,15.6,0,0,1,.154,2.266c0,.923-.065,1.817-.145,2.636a9.5,9.5,0,0,1-1.787,4.728,26.653,26.653,0,0,1-2.056,2.043,2.962,2.962,0,0,0-.212.218c-1.147,1.327-.985,3.913.494,7.907a.363.363,0,0,1-.018.3.357.357,0,0,1-.229.181L187.234,41.82a1.542,1.542,0,0,0,1.2.64,1.177,1.177,0,0,0,.333-.047l10.462-2.7a1.418,1.418,0,0,0,.972-1.868c-.841-2.271-1.794-5.566-.686-6.849l.022-.025C199.847,30.639,199.814,30.762,201.206,29.349Z"
              transform="translate(-171.646 -7.511)"
              fill="#ffbd86"
            ></path>
            <g
              id="Group_2924"
              data-name="Group 2924"
              transform="translate(7.05 3.025)"
            >
              <path
                id="Path_8093"
                data-name="Path 8093"
                d="M295.217,255.281l-.264-2.834,6.9,1.093a7.058,7.058,0,0,1-.191,1.122c-.05.233.161.492.058.7a2.755,2.755,0,0,1-4.533.579.145.145,0,0,0-.133-.047,2.076,2.076,0,0,1-.654,0,2.237,2.237,0,0,1-1.187-.61Z"
                transform="translate(-282.174 -238.283)"
                fill="#dc4955"
              ></path>
              <path
                id="Path_8094"
                data-name="Path 8094"
                d="M289.417,242.672a2.708,2.708,0,0,1-.232.658,5.271,5.271,0,0,1-1.368.181h0a5.372,5.372,0,0,1-2.772-.774,4.1,4.1,0,0,1-2.215.65h-.017c-.052-.044-.361-.117-.4-.163l-.947-3.714,8.012,2.079A6.288,6.288,0,0,1,289.417,242.672Z"
                transform="translate(-269.634 -226.254)"
                fill="#d82f3c"
              ></path>
              <path
                id="Path_8095"
                data-name="Path 8095"
                d="M241.541,257.292l1.715,5.7a.5.5,0,0,1-.559.644l-1.437-.268a1.128,1.128,0,0,1-.868-.688l-.255-.323-1.952-4.839Z"
                transform="translate(-229.391 -242.788)"
                fill="#df646e"
              ></path>
              <path
                id="Path_8096"
                data-name="Path 8096"
                d="M268.743,263.565l-1.716-.272a1.129,1.129,0,0,1-.868-.688l-.163-.4,1.91.361a.28.28,0,0,0,.321-.356l-1.494-5.021.842-.055,1.728,5.791A.5.5,0,0,1,268.743,263.565Z"
                transform="translate(-255.249 -242.637)"
                fill="#dc4955"
              ></path>
              <path
                id="Path_8097"
                data-name="Path 8097"
                d="M135.363,58.212a3.2,3.2,0,0,0-2.466-3.9.35.35,0,0,1-.263-.236,3.421,3.421,0,0,0-5.239-1.744.348.348,0,0,1-.484-.074,4.041,4.041,0,0,0-6.249-.31.347.347,0,0,1-.4.09,3.077,3.077,0,0,0-4.219,2.052.35.35,0,0,1-.257.255,3.627,3.627,0,0,0-2.424,5.083.349.349,0,0,1-.021.336,3.169,3.169,0,0,0,4.91,3.939h0a2.415,2.415,0,0,0,2.109,2.037,2.562,2.562,0,0,0,1.007-.04,1.963,1.963,0,0,0,1.326.664,2.143,2.143,0,0,0,1.856-.482,2.541,2.541,0,0,0,1.082.4,2.693,2.693,0,0,0,2.583-.806h0a4.063,4.063,0,0,0,.423.354,4.152,4.152,0,0,0,1.967.53,3.618,3.618,0,0,0,4.121-3.066,3.8,3.8,0,0,0-.466-2.777.348.348,0,0,1,.05-.423A3.523,3.523,0,0,0,135.363,58.212Z"
                transform="translate(-112.86 -50.618)"
                fill="#df646e"
              ></path>
              <path
                id="Path_8098"
                data-name="Path 8098"
                d="M343.787,110.665a.349.349,0,0,0-.051.423,4.063,4.063,0,0,1-5.9,5.332,4.123,4.123,0,0,0,4.647-6.052.353.353,0,0,1,.051-.43,3.461,3.461,0,0,0-.015-4.918,3.448,3.448,0,0,1,2.23,3.76A3.4,3.4,0,0,1,343.787,110.665Z"
                transform="translate(-322.047 -101.202)"
                fill="#dc4955"
              ></path>
              <g
                id="Group_2923"
                data-name="Group 2923"
                transform="translate(3.664 10.24)"
              >
                <path
                  id="Path_8099"
                  data-name="Path 8099"
                  d="M176.558,201.544a3.381,3.381,0,0,1-3.415.214,2.545,2.545,0,0,1-3.321-.258,3.1,3.1,0,0,1-3.5-2.9,2.154,2.154,0,0,1-1.251-2.071l1.051.054a1.1,1.1,0,0,0,.85,1.131.526.526,0,0,1,.4.555,2.046,2.046,0,0,0,2.519,2.149.526.526,0,0,1,.544.194,1.493,1.493,0,0,0,2.264.137.527.527,0,0,1,.671-.071,2.3,2.3,0,0,0,.928.363c1.113.177,2.393-.128,2.76-1.192Z"
                  transform="translate(-165.07 -196.527)"
                  fill="#dc4955"
                ></path>
              </g>
            </g>
            <path
              id="Path_8100"
              data-name="Path 8100"
              d="M215.015,124.818a.756.756,0,0,1-1.069,0l-.352-.211-3.329-3.324a2.364,2.364,0,0,1-.7-1.731,2.66,2.66,0,0,1,2.66-2.66,2.708,2.708,0,0,1,2.136,1.083.141.141,0,0,0,.228,0,2.644,2.644,0,0,1,2.136-1.082,2.573,2.573,0,0,1,1.286.345,2.785,2.785,0,0,1,1.116,2.509c0,.794-.473,1.539-.8,1.595-.367.063,0,0,0,0Z"
              transform="translate(-196.27 -109.195)"
              fill="#dc4955"
            ></path>
            <path
              id="Path_8101"
              data-name="Path 8101"
              d="M272.7,123.27a2.644,2.644,0,0,1-.689,1.669l-.011.012-3.675,3.669a.755.755,0,0,1-1.069,0l-.352-.351,4.113-4.16.011-.012a2.645,2.645,0,0,0,.689-1.669,2.734,2.734,0,0,0-.519-1.6A2.75,2.75,0,0,1,272.7,123.27Z"
              transform="translate(-249.491 -112.857)"
              fill="#d82f3c"
            ></path>
          </g>
          <g
            id="Group_2926"
            data-name="Group 2926"
            transform="translate(22.612)"
          >
            <path
              id="Path_8102"
              data-name="Path 8102"
              d="M51.679,146.9a.554.554,0,0,0-.689-.344.522.522,0,0,0-.359.66c.149.454.322.935.514,1.431a.848.848,0,0,1-.047.723.908.908,0,0,1-.589.448l-10.919,2.7a.939.939,0,0,1-1.089-.525c-.652-1.538-2.177-5.139-6.057-4.635l-.811.108a16.488,16.488,0,0,1-3.306.266c-1.3-.2-2.019-.722-2.252-1.628a1.635,1.635,0,0,1,.291-1.391,1.015,1.015,0,0,0-.339-1.329,3.069,3.069,0,0,0-.757-.349c-.006-.114.051-.287.335-.483a.523.523,0,0,0,.222-.35.51.51,0,0,0-.108-.4,9.021,9.021,0,0,0-1.052-.974c.455-.31,1.324-1.011,1.211-1.787-.047-.325-.28-.763-1.12-1.011-.92-.271-1.047-.612-1.048-.94s.206-.542.7-.907a8.334,8.334,0,0,0,2.381-2.525,1.427,1.427,0,0,0,.075-1.3,5.135,5.135,0,0,0-.414-.706c-.229-.347-.465-.706-.41-.932a2.744,2.744,0,0,1,.2-.477,5.073,5.073,0,0,0,.6-2.049,10.144,10.144,0,0,1,.123-1.031.529.529,0,0,0-.453-.605.548.548,0,0,0-.631.434,11.222,11.222,0,0,0-.135,1.134,4.072,4.072,0,0,1-.5,1.667,3.416,3.416,0,0,0-.282.689,2.24,2.24,0,0,0,.551,1.733,4.343,4.343,0,0,1,.333.56.407.407,0,0,1-.023.372,7.119,7.119,0,0,1-2.093,2.207,2.194,2.194,0,0,0-1.131,1.744c0,.934.616,1.588,1.824,1.944a.91.91,0,0,1,.353.162,2.351,2.351,0,0,1-.882.854.54.54,0,0,0-.152.141,1.092,1.092,0,0,0,.308,1.537c.183.14.333.267.45.375a1.367,1.367,0,0,0-.105,1.661c.2.264.536.207.977.471a2.591,2.591,0,0,0-.376,2.118c.341,1.329,1.4,2.142,3.141,2.415a15.835,15.835,0,0,0,3.636-.262l.806-.107c2.667-.346,3.927,1.712,4.891,3.987a2.035,2.035,0,0,0,1.879,1.209,2.093,2.093,0,0,0,.5-.061l10.919-2.7a2.014,2.014,0,0,0,1.289-.98,1.874,1.874,0,0,0,.1-1.577C51.99,147.8,51.823,147.337,51.679,146.9Z"
              transform="translate(-22.612 -117.596)"
            ></path>
            <path
              id="Path_8103"
              data-name="Path 8103"
              d="M101.51,2.927A22.489,22.489,0,0,0,90.067,0a17.357,17.357,0,0,0-7.747,1.7,10.848,10.848,0,0,0-5.153,5.137.518.518,0,0,0,.283.693.559.559,0,0,0,.723-.272c1.744-3.83,6.3-6.21,11.893-6.21a21.333,21.333,0,0,1,10.852,2.762,10.329,10.329,0,0,1,5.279,9.132c0,4.779-.655,6.737-4.993,10.5-.778.675-1.009,1.871-.706,3.657a.543.543,0,0,0,.541.442.571.571,0,0,0,.089-.007.529.529,0,0,0,.454-.6c-.232-1.366-.108-2.3.359-2.707,4.58-3.974,5.354-6.3,5.354-11.282A11.329,11.329,0,0,0,101.51,2.927Z"
              transform="translate(-73.296)"
            ></path>
            <path
              id="Path_8104"
              data-name="Path 8104"
              d="M108.477,60.186a3.653,3.653,0,0,0,.572.045A3.72,3.72,0,0,0,111,59.673a3.093,3.093,0,0,0,3.228,1.807,2.531,2.531,0,0,0,.428.347l1.649,4.046a1.663,1.663,0,0,0,1.273,1.009l1.716.272a1.041,1.041,0,0,0,.164.013,1.029,1.029,0,0,0,.981-1.332l-.612-1.992a2.781,2.781,0,0,0,.641.032,3.23,3.23,0,0,0,1.785.911,3.27,3.27,0,0,0,.513.041,3.237,3.237,0,0,0,3.189-2.73c.015-.1.026-.2.032-.294a4.638,4.638,0,0,0,.771-.454,4.591,4.591,0,0,0,1.334-5.908,3.947,3.947,0,0,0-1.966-6.531,3.949,3.949,0,0,0-5.873-2.011,4.543,4.543,0,0,0-2.314-1.542.526.526,0,0,0-.288,1.012,3.5,3.5,0,0,1,2.035,1.552.526.526,0,0,0,.8.121,2.9,2.9,0,0,1,4.721,1.455.526.526,0,0,0,.432.39l.022,0a2.894,2.894,0,0,1,1.423,5.063.526.526,0,0,0-.095.7,3.538,3.538,0,0,1-5.03,4.832,3.388,3.388,0,0,0,.3-.648.526.526,0,0,0-1-.344,2.32,2.32,0,0,1-3.483,1.168.526.526,0,0,0-.671.071,1.493,1.493,0,0,1-2.264-.137.526.526,0,0,0-.544-.194,2.033,2.033,0,0,1-.8.032,2.053,2.053,0,0,1-1.718-2.181.526.526,0,0,0-.4-.555,1.1,1.1,0,0,1-.358-1.994.526.526,0,1,0-.584-.876,2.157,2.157,0,0,0,.291,3.754,2.614,2.614,0,0,1-2.09.57,2.646,2.646,0,0,1-2.2-3.023A2.615,2.615,0,0,1,106.907,55a.526.526,0,0,0,.035-.558,3.072,3.072,0,0,1-.338-1.964,3.1,3.1,0,0,1,2.515-2.568.526.526,0,0,0,.425-.423,2.55,2.55,0,0,1,3.723-1.783.526.526,0,0,0,.663-.136,3.522,3.522,0,0,1,1.6-1.139.526.526,0,0,0-.346-.994,4.574,4.574,0,0,0-1.807,1.163,3.6,3.6,0,0,0-4.792,2.354,4.158,4.158,0,0,0-3.025,3.362,4.111,4.111,0,0,0,.31,2.344,3.654,3.654,0,0,0-.469,1.3A3.7,3.7,0,0,0,108.477,60.186Zm15.6,2.04a4.55,4.55,0,0,0,.8-.071,2.182,2.182,0,0,1-3.791.826.526.526,0,0,0-.509-.181,1.518,1.518,0,0,1-1.128-.218l-.15-.487a3.369,3.369,0,0,0,1.986-.809,4.565,4.565,0,0,0,2.065.883,4.657,4.657,0,0,0,.728.057Zm-6.519-.488a3.329,3.329,0,0,0,.6.235l1.271,4.135-1.679-.266a.6.6,0,0,1-.463-.367l-1.322-3.243A2.66,2.66,0,0,0,117.553,61.739Z"
              transform="translate(-98.31 -42.154)"
            ></path>
            <path
              id="Path_8105"
              data-name="Path 8105"
              d="M210.755,114.532a.526.526,0,0,0,.744.745,3.57,3.57,0,0,0,1.444-2.642,3.22,3.22,0,0,0-3.186-3.242,3.159,3.159,0,0,0-2.25.934,3.158,3.158,0,0,0-2.249-.934,3.19,3.19,0,0,0-3.187,3.187,2.894,2.894,0,0,0,.849,2.1l3.68,3.675a1.285,1.285,0,0,0,1.813,0l1.342-1.34a.526.526,0,0,0-.744-.745l-1.342,1.34a.23.23,0,0,1-.325,0l-3.681-3.676a1.864,1.864,0,0,1-.539-1.358,2.13,2.13,0,0,1,3.937-1.131.526.526,0,0,0,.892,0,2.119,2.119,0,0,1,1.8-1,2.192,2.192,0,0,1,2.133,2.19A2.734,2.734,0,0,1,210.755,114.532Z"
              transform="translate(-188.762 -101.696)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MindIcon;
