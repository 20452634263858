import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import CallIcon from "../Components/SVGs/CallIcon";
import LocationIcon from "../Components/SVGs/LocationIcon";
import VideoFilledIcon from "../Components/SVGs/VideoFilledIcon";
import Doctor from "../Components/SVGs/Doctor";
import Config from "../Config";
import axios from "axios";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import AddQuesModal from "../Components/AddQuesModal";
import { toast } from "react-toastify";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import EditQuesModal from "../Components/EditQuesModal";
import DeleteQuesModal from "../Components/DeleteQuesModal";

const Community = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [activeQues, setActiveQues] = useState("");

  const user = useSelector((state) => state.UserReducer.user);

  const fetchCommunityList = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("lang", i18n.language);
      body.append("id", id);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.GetCommunityList, body, axiosConfig);

      setLoading(false);
      if (res.data.status === "OK") {
        setData(res.data.data);
      } else {
        toast("An Error Occured", { type: "error" });
      }
    }
  };

  useEffect(() => {
    fetchCommunityList();
  }, []);

  const addQuestion = async (formVal, setFormVal) => {
    setAddLoading(true);

    const body = new FormData();
    body.append("mobile", user?.mobile);
    body.append("question", formVal?.question);
    body.append("name", user?.firstName);
    body.append("gender", formVal?.gender);
    body.append("age", formVal?.age);
    body.append("lang", i18n.language);
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(Config.AddQues, body, axiosConfig);

    if (res.data.status === "OK") {
      toast(res.data.msg || "Your Question Added Successfully", {
        type: "success",
      });
      setShowAddModal(false);
      fetchCommunityList();
      setFormVal({
        mobile: "",
        question: "",
        name: "",
        gender: "",
        age: "",
      });
    } else {
      console.log("error occured");
      toast(res.data.msg || "An Error Occured", { type: "error" });
    }
    setAddLoading(false);
  };

  const editQuestion = async (formVal, setFormVal) => {
    setEditLoading(true);

    const body = new FormData();
    body.append("mobile", user?.mobile);
    body.append("question", formVal?.question);
    body.append("questionId", activeId);
    body.append("lang", i18n.language);
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(Config.EditQues, body, axiosConfig);

    if (res.data.status === "OK") {
      toast(res.data.msg || "Your Question Edited Successfully", {
        type: "success",
      });
      setShowEditModal(false);
      fetchCommunityList();
      setFormVal({
        question: "",
      });
    } else {
      console.log("error occured");
      toast(res.data.msg || "An Error Occured", { type: "error" });
    }
    setEditLoading(false);
  };

  const openEditModal = (item) => {
    setActiveQues(item?.question);
    setActiveId(item?.id);
    setShowEditModal(true);
  };

  const deleteQuestion = async () => {
    setDeleteLoading(true);

    const body = new FormData();
    body.append("mobile", user?.mobile);
    body.append("questionId", activeId);
    body.append("lang", i18n.language);
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(Config.DeleteQues, body, axiosConfig);

    if (res.data.status === "OK") {
      toast(res.data.msg || "Your Question Deleted Successfully", {
        type: "success",
      });
      setShowDeleteModal(false);
      fetchCommunityList();
    } else {
      console.log("error occured");
      toast(res.data.msg || "An Error Occured", { type: "error" });
    }
    setDeleteLoading(false);
  };

  const openDeleteModal = (item) => {
    setActiveId(item?.id);
    setShowDeleteModal(true);
  };

  return (
    <>
      <Container>
        <AddQuesModal
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          addLoading={addLoading}
          addQuestion={addQuestion}
        />
        <EditQuesModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          Loading={editLoading}
          editQuestion={editQuestion}
          activeQues={activeQues}
        />
        <DeleteQuesModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          Loading={deleteLoading}
          deleteQuestion={deleteQuestion}
        />
        <DetailNavbar title={t("Community")} route={"/"} />
        <Wrapper>
          <div className="flex items-center justify-between">
            <Title>{t("CommunityTitle")}</Title>
            <Btn onClick={() => setShowAddModal(true)}>
              <span>{t("Add")}</span>
              <AiOutlineQuestionCircle className="w-4 h-4 text-white" />{" "}
            </Btn>
          </div>

          {loading && <LoadingComp loading={loading} />}
          {!loading && (
            <>
              {data.map((item) => (
                <SingleThread
                  navigate={navigate}
                  key={item.id}
                  t={t}
                  item={item}
                  user={user}
                  openEditModal={openEditModal}
                  openDeleteModal={openDeleteModal}
                />
              ))}
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const SingleThread = ({
  navigate,
  t,
  item,
  user,
  openEditModal,
  openDeleteModal,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <SingleCard onMouseLeave={() => setShowOptions(false)}>
      {showOptions && (
        <Options
          item={item}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
        />
      )}
      <CardWrapper>
        <CardTitle>{item?.name}</CardTitle>
        <CardWrapper className="justify-end mt-0">
          <Date>{moment(item?.creationDate).format("DD/MM/yyyy HH:mm")}</Date>
          {item?.isMe && (
            <BsThreeDotsVertical
              className="w-4 h-4 ml-2 text-gray-600 cursor-pointer"
              onClick={() => setShowOptions((prev) => !prev)}
            />
          )}
        </CardWrapper>
      </CardWrapper>

      <div onClick={() => navigate(`/community/${item?.id}`)}>
        <Ques>{item?.question}</Ques>
        <CardWrapper>
          <Info>
            {/* {t("AskedFor")} {item?.gender}, {item?.age} {t("Years")} */}
            {item?.gender}, {item?.age} {t("Years")}
          </Info>
          <CardBtn>
            <span>
              {" "}
              {item?.answerCount} {t("Answers")}
            </span>{" "}
            <FaChevronRight className="w-2.5 h-2.5 mt-1" />
          </CardBtn>
        </CardWrapper>
      </div>
    </SingleCard>
  );
};

const Options = ({ openEditModal, item, openDeleteModal }) => (
  <>
    <OptionWrapper>
      <Option onClick={() => openEditModal(item)}>Edit</Option>
      <Option onClick={() => openDeleteModal(item)}>Delete</Option>
    </OptionWrapper>
  </>
);

const LoadingComp = ({ loading }) => (
  <div className={`w-full ${loading && "h-96"} grid place-items-center`}>
    <img
      src={Images.GreenLoader}
      alt="loading..."
      className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
    />
  </div>
);

const OptionWrapper = tw.div`bg-white absolute top-4 right-10 rounded-md shadow-md z-10 border`;
const Option = tw.div`py-3.5 px-10 text-xs border-b border-gray-100 hover:bg-gray-100`;

const Container = tw.section`Container  border relative bg-gray-100 shadow-md  h-screen overflow-y-auto  overflow-x-hidden  `;
const Wrapper = tw.div`flex flex-col space-y-5 mt-5 px-4 h-full`;
const Btn = tw.button`p-1.5 px-3 bg-custom-orange text-white text-sm rounded flex justify-center items-center space-x-1`;

const SingleCard = tw.section`bg-white text-left p-4 my-16  text-center shadow-lg border  relative rounded-lg shadow-md   `;
const Title = tw.h1`text-lg text-gray-700 font-medium`;
const CardTitle = tw.h3`text-sm text-gray-500 text-left`;
const CardWrapper = tw.div`mt-2 flex items-center justify-between`;
const Date = tw.p`text-xs text-gray-400`;
const Info = tw.p`text-xs text-gray-500`;
const CardBtn = tw.p`p-1 rounded-md   text-custom-orange font-medium  text-xs cursor-pointer   flex items-center  space-x-0.5`;
const Ques = tw.h3`text-base text-left text-gray-700 font-medium`;

export default Community;
