import React from "react";

const Alert = ({ msg, color }) => {
  return (
    <div className="m-3 w-auto text-center">
      <div
        className={`${
          color === "red"
            ? "bg-red-100 text-red-700"
            : "bg-green-100 text-green-700"
        } p-1 py-2 rounded`}
        role="alert"
      >
        <span className="block sm:inline text-xs">{msg}</span>
      </div>
    </div>
  );
};

export default Alert;
