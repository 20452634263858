import React from "react";

const Mindspace = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      width="30"
      height="30"
    >
      <g id="mindspace_tips" transform="translate(-68 -1118)">
        <circle
          id="Ellipse_40"
          data-name="Ellipse 40"
          cx="18"
          cy="18"
          r="18"
          transform="translate(68 1118)"
          fill="#2e9ae5"
        ></circle>
        <g id="peace-of-mind" transform="translate(76.999 1126)">
          <path
            id="Path_8478"
            data-name="Path 8478"
            d="M17.9,11.391,15.6,7.934V6.6A7.843,7.843,0,0,0,7.871,0H7.8A7.709,7.709,0,0,0,0,7.618a7.452,7.452,0,0,0,1.3,4.207,13.52,13.52,0,0,1,2.3,7.59A.587.587,0,0,0,4.2,20h6a.587.587,0,0,0,.6-.586,1.777,1.777,0,0,1,1.8-1.758H15a.587.587,0,0,0,.6-.586V14.649l-1.308-.586,1.416-.586A1.8,1.8,0,0,1,17.4,12.305a.59.59,0,0,0,.5-.914Zm0,0"
            transform="translate(0 -0.001)"
            fill="#fff"
          ></path>
          <path
            id="Path_8480"
            data-name="Path 8480"
            d="M274.82,316.178h-1.387a3.456,3.456,0,0,1-2.427-1.005.589.589,0,0,0-.833.833,4.642,4.642,0,0,0,3.259,1.35h1.279v-.589a1.747,1.747,0,0,1,.108-.589Zm0,0"
            transform="translate(-259.07 -302.703)"
            fill="#2e9ae5"
          ></path>
          <path
            id="Path_8481"
            data-name="Path 8481"
            d="M207.3,95.652a.587.587,0,0,0-.555-.555,4.545,4.545,0,0,0-2.559.68l-.462,2.86,2.9-.425A4.537,4.537,0,0,0,207.3,95.652Zm0,0"
            transform="translate(-195.537 -91.383)"
            fill="#0f76be"
          ></path>
          <path
            id="Path_8482"
            data-name="Path 8482"
            d="M98.21,95.777a4.542,4.542,0,0,0-2.559-.68.587.587,0,0,0-.555.555,4.537,4.537,0,0,0,.68,2.559l2.886.452Zm0,0"
            transform="translate(-91.272 -91.383)"
            fill="#0f76be"
          ></path>
          <path
            id="Path_8483"
            data-name="Path 8483"
            d="M181.178,240.589a.589.589,0,1,1-.589-.589A.583.583,0,0,1,181.178,240.589Zm0,0"
            transform="translate(-172.792 -230.628)"
            fill="#2e9ae5"
          ></path>
          <path
            id="Path_8485"
            data-name="Path 8485"
            d="M152.155,60.153a.57.57,0,0,0-.777,0A4.227,4.227,0,0,0,150,62.944a4.229,4.229,0,0,0,1.378,2.791.57.57,0,0,0,.777,0,4.228,4.228,0,0,0,1.378-2.791A4.228,4.228,0,0,0,152.155,60.153Zm0,0"
            transform="translate(-143.973 -57.644)"
            fill="#2e9ae5"
          ></path>
          <path
            id="Path_8487"
            data-name="Path 8487"
            d="M70.519,152.155a4.227,4.227,0,0,1-2.791,1.378,3.883,3.883,0,0,1-2.355-.966,3.882,3.882,0,0,1-2.355.966,4.227,4.227,0,0,1-2.791-1.378.578.578,0,0,1,0-.777A4.227,4.227,0,0,1,63.017,150a3.881,3.881,0,0,1,2.355.966A3.881,3.881,0,0,1,67.728,150a4.227,4.227,0,0,1,2.791,1.378A.578.578,0,0,1,70.519,152.155Zm0,0"
            transform="translate(-57.717 -144.146)"
            fill="#2e9ae5"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Mindspace;
