import React from "react";
import tw from "tailwind-styled-components";
import Carousel from "../Components/HomePage/Carousel";
import Categories from "../Components/HomePage/Categories";
import Footer from "../Components/HomePage/Footer";
import HealthArticles from "../Components/HomePage/HealthArticles";
import HealthTips from "../Components/HomePage/HealthTips";
import HomeNavbar from "../Components/HomePage/HomeNavbar";
import SingleCallComp from "../Components/HomePage/SingleCallComp";
import Videos from "../Components/HomePage/Videos";
import LiveVideos from "../Components/HomePage/LiveVideos";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { t, i18n } = useTranslation();
  // console.log(i18n);

  return (
    <Container>
      <HomeNavbar />

      <CallWrapper>
        <SingleCallComp
          title={t("DoctorCall")}
          subTitle={t("ConsultOnline")}
          link={t("Doctor24")}
          color={"orange"}
          url={"/doctor-list"}
        />
        <SingleCallComp
          title={t("MedicalCall")}
          subTitle={t("OrderMedicine")}
          link={t("MedicalStore")}
          color={"blue"}
          url={"/medical-stores"}
        />
        <SingleCallComp
          title={t("DoctorAdvice")}
          subTitle={t("OrderMedicine")}
          link={t("GetAdvice")}
          color={"orange"}
          url={"/doctor-profile"}
        />
        <SingleCallComp
          title={t("Hospitals")}
          subTitle={t("Hospitals")}
          link={t("CheckAvailability")}
          color={"blue"}
          url={"/hospital-list"}
        />
      </CallWrapper>

      <Wrapper>
        <Carousel />
      </Wrapper>

      <Wrapper>
        <Categories />
      </Wrapper>

      <Wrapper>
        <LiveVideos />
      </Wrapper>

      <Wrapper>
        <Videos />
      </Wrapper>

      <Wrapper>
        <HealthTips />
      </Wrapper>

      <Wrapper>
        <HealthArticles />
      </Wrapper>

      <Footer />
    </Container>
  );
};

const Container = tw.section`Container shadow-lg border relative   bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;
const CallWrapper = tw.div`w-full mt-10 grid grid-cols-2 gap-y-10 gap-x-6 px-4`;
const Wrapper = tw.div`w-full mt-7 px-4`;

export default HomePage;
