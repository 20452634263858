import HomePage from "./Pages/HomePage";
import React, {useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import DoctorList from "./Pages/DoctorList";
import MedicalStores from "./Pages/MedicalStores";
import MedicineList from "./Pages/MedicineList";
import Categories from "./Pages/Categories";
import HealthArticlesTopics from "./Pages/HealthArticlesTopics";
import HealthArticlesList from "./Pages/HealthArticlesList";
import HealthArticleDetail from "./Pages/HealthArticleDetail";
import Login from "./Pages/Login";
import { useSelector } from "react-redux";
import "./i18";
import DoctorDetail from "./Pages/DoctorDetail";
import AdviserProfile from "./Pages/AdviserProfile";
import Community from "./Pages/Community";
import CommunityDetail from "./Pages/CommunityDetail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Appointment from "./Pages/Appointment";
import HospitalList from "./Pages/HospitalList";
import DepartmentList from "./Pages/DepartmentList";
import HospitalDoctorList from "./Pages/HospitalDoctorList";
import HospitalDoctorDetail from "./Pages/HospitalDoctorDetail";
import ReactGA from 'react-ga';

const TRACKING_ID = "G-7ZCB7KY38B";

ReactGA.initialize(TRACKING_ID);

function App() {
  const user = useSelector((state) => state.UserReducer.user);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  

  if (!user) return <Login />;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/doctor-list" element={<DoctorList />} />
        <Route path="/doctor-list/:id" element={<DoctorDetail />} />
        <Route path="/hospital-list" element={<HospitalList />} />
        <Route path="/hospital/:id" element={<DepartmentList />} />
        <Route
          path="/hospital/department/:id"
          element={<HospitalDoctorList />}
        />
        <Route
          path="/hospital/department/:departmentId/doctor/:id"
          element={<HospitalDoctorDetail />}
        />
        <Route path="/doctor-profile" element={<AdviserProfile />} />
        <Route path="/get-appointment" element={<Appointment />} />
        <Route path="/medical-stores" element={<MedicalStores />} />
        <Route path="/medicine-list/:pharmacyId" element={<MedicineList />} />
        <Route path="/categories/:category" element={<Categories />} />
        <Route path="/health-articles" element={<HealthArticlesTopics />} />
        <Route
          path="/health-articles/:topic"
          element={<HealthArticlesList />}
        />
        <Route
          path="/health-articles/:topic/:id"
          element={<HealthArticleDetail />}
        />
        <Route path="/community" element={<Community />} />
        <Route path="/community/:id" element={<CommunityDetail />} />
      </Routes>
    </>
  );
}

export default App;
