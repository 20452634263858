import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import { Link, useParams } from "react-router-dom";
import Config from "../Config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HealthArticleDetail = () => {
  
  const { t, i18n } = useTranslation();

  const { topic, id } = useParams();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [articleImage, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.UserReducer.user);

  const fetchArticleDetail = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("type", topic);
      body.append("articleId", id);
      body.append("lang", i18n.language);

      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.GetArticleList, body, axiosConfig);

      if (res.data.status === "OK") {
        setTitle(decodeURIComponent(res.data.list.title.replace(/\+/g, " ")));
        setDescription(
          decodeURIComponent(res.data.list.description.replace(/\+/g, " "))
        );
        setImage(res.data.list.image);
      }

      setLoading(false);
    } else {
      // dispatch(Remove_User());
    }
  };

  useEffect(() => {
    fetchArticleDetail();
  }, []);

  return (
    <Container>
      <DetailNavbar title={`${topic} `} route={-1} />

      {loading && (
        <div className={`w-full ${loading && "h-96"} grid place-items-center`}>
          <img
            src={Images.GreenLoader}
            alt="loading..."
            className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
          />
        </div>
      )}

      {!loading && (
        <Wrapper>
          <Figure>
            <img
              src={Config.ImageBasePath + articleImage}
              alt="images"
              className="w-full h-full object-cover"
            />
          </Figure>

          <Title>{title}</Title>
          <Description>{description}</Description>
        </Wrapper>
      )}
    </Container>
  );
};

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;

const Wrapper = tw.div` `;

const Figure = tw.figure`w-full h-56 `;

const Title = tw.h3`font-medium text-gray-900 px-4 mt-5 mb-3 text-xl `;
const Description = tw.p`text-sm  text-gray-500  px-4 leading-6`;

export default HealthArticleDetail;
