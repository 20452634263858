import React from "react";

const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.656 10"
      width="14"
      height="14"
      className="text-orange-500"
    >
      <g id="location_org_color" transform="translate(-60)">
        <g id="Group_2851" data-name="Group 2851" transform="translate(60)">
          <path
            id="Path_8012"
            data-name="Path 8012"
            d="M63.828,0a3.827,3.827,0,0,0-3.05,6.142L63.662,10h.333l2.884-3.858A3.828,3.828,0,0,0,63.828,0Zm0,5.1a1.27,1.27,0,1,1,1.27-1.27A1.271,1.271,0,0,1,63.828,5.1Z"
            transform="translate(-60 0)"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;
