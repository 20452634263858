//Development Server
// const baseURL = "http://3.138.202.75:3003";

//Production Server
//const baseURL = "https://nurses.rapidtestandtrace.com/api";
//Development Server
//const baseURL = "http://52.77.227.210/AWCC/api/api";
const baseURL = "https://health.afghan-wireless.com/api/api";
//const baseURL = "http://health.membamobile.com/api/api";
const GetAccessToken = () => {
  // const user = localStorage.getItem("user")
  //   ? JSON.parse(localStorage.getItem("user"))
  //   : null;
  const user = null;

  if (user) return user.accessToken;

  return null;
};

const Config = {
  AxiosConfig: {
    headers: {
      authorization: `Bearer ${GetAccessToken()}`,
    },
  },
  RegisterApi: baseURL + "/register",
  VerifyOtpApi: baseURL + "/otp",
  GetDoctorsList: baseURL + "/getdoctors",
  GetDoctorDetail: baseURL + "/getdoctorsdetails",
  GetPharmaciesList: baseURL + "/getpharmacies",
  GetMedicineList: baseURL + "/getmedicines",
  SearchMedicineList: baseURL + "/searchmedicines",
  GetArticleList: baseURL + "/getarticlesnew",
  GetArticleDetail: baseURL + "/getarticlesnew",
  UnsuscribeUser: baseURL + "/unsubscribe",
  AddQues: baseURL + "/addquestion",
  EditQues: baseURL + "/editquestion",
  DeleteQues: baseURL + "/removequestion",
  Reply: baseURL + "/replycommunity",
  DeleteReply: baseURL + "/removereply",
  EditReply: baseURL + "/editreply",
  GetCommunityList: baseURL + "/getcommunitydata",
  GetCommunityDetailList: baseURL + "/getcommunitydetail",
  RegisterForEvent: baseURL + "/registerforevent",
  GetSlots: baseURL + "/getslots",
  BookSlot: baseURL + "/bookavailableslot",

  GetHospitalsList: baseURL + "/gethospitals",
  GetDepartmentList: baseURL + "/getdepartments",
  GetHDoctorsList: baseURL + "/gethdoctors",

  //GetVideoList: "http://132.148.22.155/partners/awcc_health_media/",
  GetVideoList: "https://health.afghan-wireless.com/MEDIA/",
  // ImageBasePath: "https://health.afghan-wireless.com/IMAGES/",
  ImageBasePath: "/images/",
  DoctorImageBasePath: "https://health.afghan-wireless.com/MEDIA/doctors/",
  DepartmentsImageBasePath: "https://health.afghan-wireless.com/MEDIA/departments/",
  HDoctorImageBasePath: "https://health.afghan-wireless.com/MEDIA/hdoctors/",
};

export const PaginationLimit = 10;

export default Config;
