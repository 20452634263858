import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import { Link, useParams } from "react-router-dom";
import Config from "../Config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HealthArticlesList = () => {
  //const list = [1, 2, 3];
  const { t, i18n } = useTranslation();

  const [list, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.UserReducer.user);

  const { topic } = useParams();


  const fetchArticleList = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("type", topic);
      body.append("articleId", 0);
      body.append("lang", i18n.language);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.GetArticleList, body, axiosConfig);

      if (res.data.status === "OK") setData(res.data.list);

      setLoading(false);
    } else {
      // dispatch(Remove_User());
    }
  };

  useEffect(() => {
    // console.log(topic);
    // console.log(t(topic));
    fetchArticleList();
  }, []);


  return (
    <Container>
      <DetailNavbar title={t(topic[0].toUpperCase() + topic.substring(1))} route={-1} />


       {loading && (
          <div
            className={`w-full ${loading && "h-96"} grid place-items-center`}
          >
            <img
              src={Images.GreenLoader}
              alt="loading..."
              className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
            />
          </div>
        )}

      {!loading && <Wrapper>
          {list.map((item, index) => (
            //console.log(decodeURIComponent(item.title.replace(/\+/g, ' '))) ,
            <SingleItem
              key={index}
              img={Config.ImageBasePath + item.image}
              title={decodeURIComponent(item.title.replace(/\+/g, ' '))}
              topic={t(topic[0].toUpperCase() + topic.substring(1))}
              id={item.id}
            />
          ))}
        </Wrapper>
      }
    </Container>
  );
};

const SingleItem = ({ img, title, topic, id }) => (
  <Link to={`/health-articles/${topic}/${id}`}>
    <SingleItemWrapper>
      <Figure>
        <img src={img} alt="images" className="w-full h-full object-cover" />
      </Figure>

      <TextWrapper>
        <Title>{title}</Title>
        <Topic>{topic}</Topic>
      </TextWrapper>
    </SingleItemWrapper>
  </Link>
);

const Container = tw.section`Container shadow-lg border relative bg-gray-100 shadow-md h-screen overflow-y-auto  overflow-x-hidden `;

const Wrapper = tw.div`grid  gap-5 mt-5 px-4`;

const SingleItemWrapper = tw.div`
  w-full relative bg-white shadow-md p-2 overflow-hidden rounded-lg flex space-x-4 `;

const Figure = tw.figure`w-36 h-20 rounded-lg overflow-hidden`;

const TextWrapper = tw.div`w-full flex flex-col justify-around`;
const Title = tw.h3`font-medium text-gray-900 -mt-2`;
const Topic = tw.p`text-xs  text-gray-500 uppercase tracking-wider`;

export default HealthArticlesList;
