import React from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      {/* <Row>
        <LinkItem path="/">Home</LinkItem>
        <LinkItem path="/doctor-list">Consult with Doctor</LinkItem>
        <LinkItem>Terms & Conditions</LinkItem>
        <LinkItem>FAQ</LinkItem>
      </Row> */}
      <Row>
        <p className="w-full text-center">{t("AfghanWireless")}</p>
      </Row>
    </Wrapper>
  );
};

const Wrapper = tw.footer`
bg-custom-blue text-white p-4 mt-8`;

const LinkItem = tw.p`text-gray-100 hover:underline cursor-pointer hover:text-white`;

const Row = tw.div`flex items-center space-x-3 justify-between text-sm`;

export default Footer;
