import React from "react";

const AWCCLogo = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="36"
        height="50"
        viewBox="0 0 36 50"
      >
        <defs>
          <clipPath id="clipPath">
            <path
              id="Path_7987"
              data-name="Path 7987"
              d="M117.23,139.3v7.737a3.493,3.493,0,0,1-1.771,3.153l-7.713,4.511-8.246,4.8,4.317,2.522a5.031,5.031,0,0,0,4.827.121l13.024-7.591a4.277,4.277,0,0,0,2.474-4.1l-.049-11.86c-.024-.024-2.207,3.2-6.864.7"
              transform="translate(-99.5 -138.599)"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="-4.312"
            y1="4.991"
            x2="-4.297"
            y2="4.991"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#148bcc" />
            <stop offset="1" stopColor="#005a9a" />
          </linearGradient>
          <clipPath id="clipPath-2">
            <path
              id="Path_7989"
              data-name="Path 7989"
              d="M56.542,99.571c-2.983,1.746-2.522,4.172-2.522,4.172l.049,15.231a4.508,4.508,0,0,0,2.5,4.1l12.758,7.422a5.073,5.073,0,0,0,5.19-.1l4.657-2.716-10.114-5.287-6.088-3.541a2.824,2.824,0,0,1-2.062-2.813V107.7c.024-2.741.024-10.7.024-10.7Z"
              transform="translate(-53.994 -97)"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient-2"
            x1="-2.907"
            y1="3.873"
            x2="-2.896"
            y2="3.873"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#097fb6" />
            <stop offset="0.124" stopColor="#2d99cc" />
            <stop offset="0.273" stopColor="#47b8e6" />
            <stop offset="0.347" stopColor="#4fc6f2" />
            <stop offset="0.467" stopColor="#43acd8" />
            <stop offset="0.72" stopColor="#2881b0" />
            <stop offset="0.904" stopColor="#126b9b" />
            <stop offset="1" stopColor="#046394" />
          </linearGradient>
          <clipPath id="clipPath-3">
            <path
              id="Path_7991"
              data-name="Path 7991"
              d="M68.941,74.487,56.662,81.618a4.279,4.279,0,0,0-2.524,4.2l.025,5.336,11.029-6.476,4.779-2.765a3.112,3.112,0,0,1,3.872-.267L80.8,85.62a5.415,5.415,0,0,1,4.142-5.481L74.823,74.245a4.224,4.224,0,0,0-2.647-.946,5.35,5.35,0,0,0-3.235,1.188"
              transform="translate(-54.117 -73.299)"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient-3"
            x1="-3.962"
            y1="5.331"
            x2="-3.948"
            y2="5.331"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#077fb6" />
            <stop offset="0.032" stopColor="#077fb6" />
            <stop offset="0.143" stopColor="#2b98cb" />
            <stop offset="0.279" stopColor="#46b8e5" />
            <stop offset="0.347" stopColor="#4ec6f2" />
            <stop offset="0.537" stopColor="#3db0e5" />
            <stop offset="0.842" stopColor="#2095d3" />
            <stop offset="1" stopColor="#128bcc" />
          </linearGradient>
        </defs>
        <g
          id="awcc_afghan_wireless_logo"
          transform="translate(-53.994 -73.299)"
        >
          <g
            id="Group_2820"
            data-name="Group 2820"
            transform="translate(53.994 73.299)"
          >
            <g
              id="Group_2815"
              data-name="Group 2815"
              transform="translate(11.357 15.597)"
            >
              <g
                id="Group_2814"
                data-name="Group 2814"
                clipPath="url(#clipPath)"
              >
                <path
                  id="Path_7986"
                  data-name="Path 7986"
                  d="M103.363,112.368,88.69,142.273,59.1,127.405,73.774,97.5Z"
                  transform="translate(-68.899 -107.468)"
                  fill="url(#linear-gradient)"
                />
              </g>
            </g>
            <g
              id="Group_2817"
              data-name="Group 2817"
              transform="translate(0 5.749)"
            >
              <g
                id="Group_2816"
                data-name="Group 2816"
                clipPath="url(#clipPath-2)"
              >
                <path
                  id="Path_7988"
                  data-name="Path 7988"
                  d="M10.008,104.65-19,72.708,12.166,43.7,41.174,75.642Z"
                  transform="translate(1.296 -56.627)"
                  fill="url(#linear-gradient-2)"
                />
              </g>
            </g>
            <g
              id="Group_2819"
              data-name="Group 2819"
              transform="translate(0.03)"
            >
              <g
                id="Group_2818"
                data-name="Group 2818"
                clipPath="url(#clipPath-3)"
              >
                <path
                  id="Path_7990"
                  data-name="Path 7990"
                  d="M20.8,35.19,51.828,17,67.562,43.946,36.51,62.112Z"
                  transform="translate(-28.966 -30.655)"
                  fill="url(#linear-gradient-3)"
                />
              </g>
            </g>
            <path
              id="Path_7992"
              data-name="Path 7992"
              d="M176.71,110.853a3.905,3.905,0,1,1-3.9-3.953,3.928,3.928,0,0,1,3.9,3.953"
              transform="translate(-140.71 -98.827)"
              fill="#f47920"
            />
            <path
              id="Path_7993"
              data-name="Path 7993"
              d="M54.485,271.532H54l1.091-3.032h.364l1.091,3.032h-.461l-.218-.679h-1.14Zm.825-2.4-.437,1.31h.873Z"
              transform="translate(-53.999 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_7994"
              data-name="Path 7994"
              d="M66.061,271.532H65.6V268.5h1.916v.437H66.061v.9h1.261v.388H66.061Z"
              transform="translate(-62.785 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_7995"
              data-name="Path 7995"
              d="M76.834,269.858v.606a1.082,1.082,0,0,1-1.067,1.091,1.133,1.133,0,0,1-.97-.582,1.167,1.167,0,0,1-.1-.534v-.9a1.056,1.056,0,0,1,1.067-1.043,1.088,1.088,0,0,1,1.067.9h-.461a.62.62,0,0,0-1.213.17v.873a.556.556,0,0,0,.121.437.633.633,0,0,0,.485.243.623.623,0,0,0,.509-.291.649.649,0,0,0,.1-.412v-.121h-.606v-.437Z"
              transform="translate(-69.678 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_7996"
              data-name="Path 7996"
              d="M86.061,271.532H85.6V268.5h.461v1.31h1.213V268.5h.461v3.032h-.461V270.2H86.061Z"
              transform="translate(-77.861 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_7997"
              data-name="Path 7997"
              d="M95.985,271.532H95.5l1.091-3.032h.364l1.091,3.032h-.485l-.218-.679H96.2Zm.8-2.4-.437,1.31h.873Z"
              transform="translate(-85.336 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_7998"
              data-name="Path 7998"
              d="M107.537,271.532H107.1V268.5h.412l1.358,2.159V268.5h.461v3.032h-.437l-1.358-2.159Z"
              transform="translate(-94.096 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_7999"
              data-name="Path 7999"
              d="M124.44,271.532h-.388L123.3,268.5h.485l.485,2.159.582-2.159h.315l.582,2.159.485-2.159h.485l-.752,3.032h-.388L125,269.349Z"
              transform="translate(-106.324 -221.556)"
              fill="#10437b"
            />
            <rect
              id="Rectangle_1452"
              data-name="Rectangle 1452"
              width="0.461"
              height="3.032"
              transform="translate(20.756 46.944)"
              fill="#10437b"
            />
            <path
              id="Path_8000"
              data-name="Path 8000"
              d="M143.561,271.532H143.1V268.5h1.213a.841.841,0,0,1,.873.873.851.851,0,0,1-.582.8l.679,1.358h-.509l-.606-1.31h-.606Zm.7-1.7a.452.452,0,0,0,.485-.461.446.446,0,0,0-.461-.461h-.728v.922Z"
              transform="translate(-121.282 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_8001"
              data-name="Path 8001"
              d="M154.137,271.1h1.455v.437H153.7V268.5h1.892v.437h-1.455v.873H155.4v.388h-1.261Z"
              transform="translate(-129.288 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_8002"
              data-name="Path 8002"
              d="M164.061,271.1h1.479v.437H163.6V268.5h.461Z"
              transform="translate(-136.764 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_8003"
              data-name="Path 8003"
              d="M173.261,271.1h1.455v.437H172.8V268.5h1.916v.437h-1.455v.873H174.5v.388h-1.237Z"
              transform="translate(-143.665 -221.556)"
              fill="#10437b"
            />
            <path
              id="Path_8004"
              data-name="Path 8004"
              d="M182.467,270.728a1.269,1.269,0,0,0,.825.291c.388,0,.606-.17.606-.437a.367.367,0,0,0-.17-.34.678.678,0,0,0-.461-.1,1.127,1.127,0,0,1-.582-.146.877.877,0,0,1,.582-1.6,1.808,1.808,0,0,1,.97.291l-.243.388a1.283,1.283,0,0,0-.728-.218c-.315,0-.485.146-.485.412a.463.463,0,0,0,.17.34.974.974,0,0,0,.388.1,1.858,1.858,0,0,1,.631.146.877.877,0,0,1,.412.728.916.916,0,0,1-.194.558,1.114,1.114,0,0,1-.873.34,1.73,1.73,0,0,1-1.116-.388Z"
              transform="translate(-150.785 -221.48)"
              fill="#10437b"
            />
            <path
              id="Path_8005"
              data-name="Path 8005"
              d="M192.367,270.728a1.269,1.269,0,0,0,.825.291c.388,0,.606-.17.606-.437a.424.424,0,0,0-.17-.34.678.678,0,0,0-.461-.1,1.127,1.127,0,0,1-.582-.146.877.877,0,0,1,.582-1.6,1.808,1.808,0,0,1,.97.291l-.243.388a1.283,1.283,0,0,0-.728-.218c-.315,0-.485.146-.485.412a.463.463,0,0,0,.17.34.861.861,0,0,0,.388.1,1.858,1.858,0,0,1,.631.146.877.877,0,0,1,.412.728.916.916,0,0,1-.194.558,1.114,1.114,0,0,1-.873.34,1.73,1.73,0,0,1-1.116-.388Z"
              transform="translate(-158.284 -221.48)"
              fill="#10437b"
            />
            <rect
              id="Rectangle_1453"
              data-name="Rectangle 1453"
              width="1.067"
              height="3.323"
              transform="translate(34.884 41.438)"
              fill="#10437b"
            />
            <path
              id="Path_8006"
              data-name="Path 8006"
              d="M115.758,260.4a.583.583,0,1,0,.558.582.57.57,0,0,0-.558-.582"
              transform="translate(-100.218 -215.421)"
              fill="#10437b"
            />
            <path
              id="Path_8007"
              data-name="Path 8007"
              d="M107.458,260.4a.583.583,0,1,0,.558.582.555.555,0,0,0-.558-.582"
              transform="translate(-93.949 -215.421)"
              fill="#10437b"
            />
            <path
              id="Path_8008"
              data-name="Path 8008"
              d="M101.858,260.4a.583.583,0,1,0,.558.582.57.57,0,0,0-.558-.582"
              transform="translate(-89.72 -215.421)"
              fill="#10437b"
            />
            <path
              id="Path_8009"
              data-name="Path 8009"
              d="M178.558,246.864a.583.583,0,1,0-.558-.582.57.57,0,0,0,.558.582"
              transform="translate(-147.603 -204.286)"
              fill="#10437b"
            />
            <path
              id="Path_8010"
              data-name="Path 8010"
              d="M166.658,246.864a.583.583,0,1,0-.558-.582.586.586,0,0,0,.558.582"
              transform="translate(-138.658 -204.286)"
              fill="#10437b"
            />
            <path
              id="Path_8011"
              data-name="Path 8011"
              d="M139.058,246.664a.583.583,0,1,0-.558-.582.57.57,0,0,0,.558.582"
              transform="translate(-117.814 -204.134)"
              fill="#10437b"
            />
            <path
              id="Path_8012"
              data-name="Path 8012"
              d="M79.558,260.4a.583.583,0,1,0,.558.582.586.586,0,0,0-.558-.582"
              transform="translate(-72.879 -215.421)"
              fill="#10437b"
            />
            <path
              id="Path_8013"
              data-name="Path 8013"
              d="M73.858,260.4a.583.583,0,1,0,.558.582.555.555,0,0,0-.558-.582"
              transform="translate(-68.618 -215.421)"
              fill="#10437b"
            />
            <path
              id="Path_8014"
              data-name="Path 8014"
              d="M135.12,249.023h.121a.378.378,0,0,0,.388-.364V245.7h-1.067v2.328h-2.6V245.7H130.9v2.959a.379.379,0,0,0,.388.364"
              transform="translate(-112.054 -204.286)"
              fill="#10437b"
            />
            <path
              id="Path_8015"
              data-name="Path 8015"
              d="M70.426,249.047h.121a.378.378,0,0,0,.388-.364V245.7H69.868v2.377H68.607v-2.353H67.54v2.353H66.278v-2.353H65.187v2.353h-.655v-2.353H63.514v2.353h-.7v-2.353H61.743v2.353h-.825v-2.353H59.851v2.353H58.59v-.485a2.049,2.049,0,0,0-.315-1.116,1.41,1.41,0,0,0-1.261-.752H55.8v.437H54.2v3.056h1.067v-2.062H55.8v1.5c0,.267.121.388.34.388m1.358-.994h-.655v-1.407a.7.7,0,0,1,.461.146.533.533,0,0,1,.218.461v.8Z"
              transform="translate(-54.15 -204.286)"
              fill="#10437b"
            />
            <path
              id="Path_8016"
              data-name="Path 8016"
              d="M156.568,249.047,156.277,248h1.091l-.291,1.043m4.8,0h.509a.379.379,0,0,0,.388-.364v-2.619h-.7a2.131,2.131,0,0,0-1.5.509,1.348,1.348,0,0,0-.461,1.043v.437h-1.868l.315-1.043h-3.493l.315,1.043h-1.213V245.7H153.1v3.347m8.683-.994h-.655v-.437q0-.509.655-.509Z"
              transform="translate(-128.743 -204.286)"
              fill="#10437b"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default AWCCLogo;
