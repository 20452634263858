import React from "react";

const MenuIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 34 34"
      >
        <g id="menu_icon" transform="translate(-10 -132)">
          <rect
            id="Rectangle_15"
            data-name="Rectangle 15"
            width="34"
            height="34"
            rx="10"
            transform="translate(10 132)"
            fill="#1467b3"
          />
          <path
            id="icon_burger_menu"
            d="M3,6A1,1,0,0,1,4,5H20a1,1,0,0,1,0,2H4A1,1,0,0,1,3,6Zm0,6a1,1,0,0,1,1-1H20a1,1,0,0,1,0,2H4A1,1,0,0,1,3,12Zm1,5a1,1,0,0,0,0,2H20a1,1,0,0,0,0-2Z"
            transform="translate(15 137)"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
};

export default MenuIcon;
