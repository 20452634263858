import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import DetailNavbar from "../Components/DetailNavbar";
import CallIcon from "../Components/SVGs/CallIcon";
import LocationIcon from "../Components/SVGs/LocationIcon";
import VideoFilledIcon from "../Components/SVGs/VideoFilledIcon";
import Doctor from "../Components/SVGs/Doctor";
import Config from "../Config";
import axios from "axios";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User, Save_Doc_Data } from "../Redux/actions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LiveDoctorSubsModel from "../Components/LiveDoctorSubsModel";
import InfoModel from "../Components/InfoModel";

const AdviserProfile = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openInfoModel, setOpenInfoModel] = useState(false);
  const [infoModelMsg, setInfoModelMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.UserReducer.user);

  const openModelFunc = () => {
    setOpenModel(true);
  };
  const closeModelFunc = () => setOpenModel(false);
  const closeInfoModelFunc = () => setOpenInfoModel(false);

  const WhatsAppHandler = (mobile) => {
    window.open(`https://wa.me/${mobile}`, "_blank");
  };

  const fetchDocotorsDetail = async () => {
    if (user?.mobile) {
      setLoading(true);

      const body = new FormData();
      body.append("mobile", user?.mobile);
      body.append("lang", i18n.language);
      body.append("id", id);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const res = await axios.post(Config.GetDoctorDetail, body, axiosConfig);

      setLoading(false);
      if (res.data.status === "OK") {
        setData(res.data.data);
      } else {
        dispatch(Remove_User());
        window.location.replace(`/`);
      }
    }
  };

  useEffect(() => {
    //fetchDocotorsDetail();
  }, []);

  const subscribeEventApi = async () => {
    setIsLoading(true);

    const body = new FormData();
    body.append("mobile", user?.mobile);

    const resp = await axios.post(Config.RegisterForEvent, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
    if (resp.data.status === "OK") {
      // setOpenModel(false);
      // setInfoModelMsg(resp.data.msg);
      // setOpenInfoModel(true);
      navigate("/get-appointment");
    } else {
      setOpenModel(false);
      setInfoModelMsg(resp.data.msg);
      setOpenInfoModel(true);
    }
    setIsLoading(false);
  };

  const goToAppointment = () => {
    //navigate(`/get-appointment`);
    openModelFunc();
  };

  return (
    <Container>
      {openInfoModel && (
        <InfoModel
          setOpenModel={setOpenInfoModel}
          closeModelFunc={closeInfoModelFunc}
          msg={infoModelMsg}
        />
      )}

      {openModel && (
        <LiveDoctorSubsModel
          setOpenModel={setOpenModel}
          isLoading={isLoading}
          closeModelFunc={closeModelFunc}
          registerHandler={subscribeEventApi}
        />
      )}

      <DetailNavbar title={t("Doctor Detail")} route={"/"} />
      <Wrapper>
        <ProfileContainer>
          {/* loader */}
          {loading && <LoadingComp loading={loading} />}
          {!loading && (
            <>
              <Figure>
                <div
                  style={{ background: "#f1f5f8" }}
                  className="w-28 h-28 rounded-full flex justify-center items-center  shadow-md overflow-hidden"
                >
                  {!data?.photo && <Doctor />}
                  {/* {data?.photo && ( */}
                    {/* <img
                      src={Config.DoctorImageBasePath + data.id + ".jpeg"}
                      alt=""
                      className="w-full h-full object-cover"
                    /> */}
                  {/* )} */}
                </div>
              </Figure>
              <Name>{"Mohammadullah Alishungi"}</Name>
              <Designation>{"Internal Medicine Specialist"}</Designation>
              <Location>
                {" "}
                <LocationIcon />
                <span>{"1st Street, Karte-e-Now, Fakoor Plaza, District 8, Kabul"}</span>
              </Location>
              <Wrapper2>
                <Btn onClick={() => goToAppointment()}>
                  {t("Detail_Btn_Text")}
                </Btn>
                <small className="text-xs block mt-2 mb-5 text-gray-500">
                  *{t("Btn_Small_Text")}
                </small>
                {/* <SubTitle>{t("Contact")}</SubTitle> */}
                {/* <a
                  href={`tel:${data?.msisdn}`}
                  className="flex justify-center items-center space-x-2"
                >
                  <img
                    src={Images.WhatsApp}
                    alt="create icon"
                    className="w-6"
                  />{" "}
                  <span>{data?.msisdn}</span>
                </a> */}
              </Wrapper2>
              <Wrapper2>
                <Box>
                  <SubTitle>{t("Experience")}</SubTitle>
                  <Number>{"25 Year"} +</Number>
                  <Para>
                    {/* {t("Experience_as")}  */}
                    {" I have worked as internal medicine \
                    specialist and trainee doctor in Wazir Akbar Khan, Jamhuriat and Ibnecina Hospitals, \
                    Health and Human Services Program Manager with CDC-USAID, Private Health Sector Coordinator with Futures Group Global Outreach (FGGO)-USAID, Technical Health Advisor with Management Science for Health (MSH), , and recently as Director of Jamhoriat Hospital with MoPH. During my employment with mentioned national and international organizations, I was directly involved Patient evaluation and management, project management, research, training and capacity building. In addition to my experience, I hold a Master degree in Medicine from Kabul Kabul Medical University, diploma in Business Administration from KH-Noor, Kabul, diploma in leadership and project management from UNITAR, Hiroshima, Japan, and diploma in hospital management from TATA Institute of Social Science, Mumbai, India."}{" "}
                  </Para>
                </Box>
              </Wrapper2>
              {/* <Wrapper2>
                <Box>
                  <SubTitle>{t("About_Doctor")}</SubTitle>
                  <Para className="text-justify">{data?.aboutDoctor}</Para>
                </Box>
              </Wrapper2> */}
            </>
          )}
        </ProfileContainer>
      </Wrapper>
    </Container>
  );
};

const LoadingComp = ({ loading }) => (
  <div className={`w-full ${loading && "h-96"} grid place-items-center`}>
    <img
      src={Images.GreenLoader}
      alt="loading..."
      className={` w-14 lg:w-20 mx-auto ${loading ? "" : "hidden"}`}
    />
  </div>
);

const Container = tw.section`Container  border relative bg-gray-100 shadow-md  h-screen overflow-y-auto  overflow-x-hidden    `;
const ProfileContainer = tw.section`bg-white p-8 my-16 pt-16 text-center shadow-lg border full relative rounded-lg shadow-md   `;
const Wrapper = tw.div`flex flex-col space-y-5 mt-5 px-4`;

const Figure = tw.figure`w-full flex absolute -top-14 left-0 right-0 justify-center items-center `;
const Name = tw.figure`text-lg text-gray-700 font-medium`;
const Designation = tw.div`text-sm text-gray-500 mb-3`;

const Box = tw.div`w-full h-full bg-gray-50 rounded-md p-5 px-3.5 `;
const Number = tw.h3`text-xl font-medium text-gray-700`;
const Para = tw.h3`text-sm  text-gray-500 mt-2`;

const SingleItemWrapper = tw.div`
w-full bg-white shadow-md p-3 rounded-lg flex space-x-2 `;
const Wrapper2 = tw.div` w-full py-5  border-t border-gray-200 `;
const SubTitle = tw.p`text-sm text-gray-500 tracking-widest font-medium mb-3`;

const TextWrapper = tw.div`w-full`;
const Title = tw.h3`font-medium text-gray-900`;
const Location = tw.div`flex space-x-1 items-center justify-center text-sm text-gray-500 w-full mb-5`;
const Icons = tw.div`flex flex-col space-y-2`;

const Btn = tw.button`w-full mt-5 mx-auto bg-custom-orange py-2 rounded-md justify-center items-center text-white`;

export default AdviserProfile;
