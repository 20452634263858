import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import tw from "tailwind-styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";

import { useTranslation } from "react-i18next";

const Videos = () => {
  const { t, i18n } = useTranslation();

  const list = [1, 2, 3, 4, 5];
  const [videoList, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchVideoList = async () => {
    setLoading(true);

    const body = new FormData();

    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(
      Config.GetVideoList + "bestvideos" + "/data.json",
      body,
      axiosConfig
    );
    if (res.data.status === "OK") setData(res.data.list);

    setLoading(false);
  };

  useEffect(() => {
    fetchVideoList();
  }, []);

  return (
    <>
      <TitleWrapper>
        <Title>{t("Videos")}</Title>

        <Link to="/categories/bestvideos">
          <ViewAll>{t("ViewDetails")}</ViewAll>
        </Link>
      </TitleWrapper>
      <Wrapper>
        <Swiper
          slidesPerView={1.5}
          spaceBetween={20}
          scrollbar={true}
          navigation={true}
          modules={[Keyboard, Scrollbar, Navigation, Pagination]}
          className="VideoSwiper"
        >
          {videoList.map((videoObj, index) => (
            <SwiperSlide key={index}>
              {/* <SingleVideo {...videoObj} /> */}
              <SingleVideo
                t={t}
                video={videoObj.video}
                poster={videoObj.poster}
                title={videoObj.title}
                views={videoObj.views}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
    </>
  );
};

const SingleVideo = ({ video, poster, title, views, t }) => (
  <SingleVideoWrapper>
    <ReactPlayer
      url={video}
      controls={true}
      width="100%"
      poster={poster}
      playing={true}
      light={poster}
      height={150}
      style={{ borderRadius: 11, overflow: "hidden" }}
    />
    {/* <Player
     src={video}
       poster={poster} >
    </Player> */}
    <VideoTitle>{title}</VideoTitle>
    <VideoCategory>
      {views} {t("Views")}
    </VideoCategory>
  </SingleVideoWrapper>
);

const Wrapper = tw.div`w-full `;
const TitleWrapper = tw.div`w-full mb-4 flex items-center justify-between`;
const Title = tw.h3`text-gray-900 font-bold  text-lg  `;
const ViewAll = tw.p`text-blue-500 text-sm cursor-pointer `;
const SingleVideoWrapper = tw.div`w-full`;
const VideoTitle = tw.p`text-gray-800 font-medium text-sm mt-1`;
const VideoCategory = tw.p`text-gray-500 text-xs `;

export default Videos;
